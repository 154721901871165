// export class BdoActionsProvider {
import { kpoActions } from "../../../../_store/kpo.slice";
import { ORDER_STATUS } from "../../../../domain/Orders/OrderStatus";
import { KPO_STATUS } from "../../../../domain/KpoCardStatus";
import { ordersActions } from "../../../../_store/order.slice";

export function useGetAction({ kpoItem, orderId, transactionType, transportType, kpoCards, orderStatus, performAll }) {
  const { kpoId, cardNumber, status } = kpoItem;
  const cardNumberQueryable = cardNumber?.replace(/\//g, "-");
  const isPurchase = transactionType === "Purchase";
  const isSale = transactionType === "Sale";
  const isTransportInternal = transportType === "Internal";
  const kpoIds = performAll ? [] : [kpoId];
  const cardPromptForm = performAll ? "kart" : "karty";
  const cardNumberPrompt = cardNumber ?? "";

  if (!kpoCards) {
    return [];
  }

  return [
    {
      name: "Aktualizuj dane karty KPO",
      action: (kpoItems) => kpoActions.updateKpoCardsData({ orderId, transactionType, kpoItems, performAll: false }),
      isVisible: (status === KPO_STATUS.PLANNED || status === KPO_STATUS.APPROVED) && isSale,
      isPerformAllVisible: (orderStatus === ORDER_STATUS.CREATED || orderStatus === ORDER_STATUS.CONFIRMED) && kpoCards.some(x => x.status === KPO_STATUS.PLANNED || x.status === KPO_STATUS.APPROVED) && isSale,
      type: "UpdatePlannedOrApprovedKpoCards",
      promptTitle: performAll ? "Aktualizuj karty KPO" : "Aktualizuj kartę KPO",
      expectedStatus: [KPO_STATUS.PLANNED],
      submitBtnText: "Aktualizuj",
    },
    {
      name: "Zatwierdź",
      action: (kpoItems) => kpoActions.approveKpoCards({ orderId, transactionType, kpoIds: kpoItems, performAll: false }),
      isVisible: status === "Planned" && isSale,
      isPerformAllVisible: orderStatus === ORDER_STATUS.CREATED && kpoCards.some(x => x.status === KPO_STATUS.PLANNED),
      type: "Prompt",
      promptTitle: performAll ? "Zatwierdź karty KPO" : "Zatwierdź wybraną kartę KPO",
      expectedStatus: [KPO_STATUS.PLANNED]
    },
    {
      name: "Wygeneruj potwierdzenie (przekazujący)",
      action: (kpoItems) => kpoActions.generateKpoCardsConfirmationAsSender({ orderId, kpoItems, performAll: false }),
      isVisible: status === KPO_STATUS.APPROVED && isSale, // TODO check rejected
      isPerformAllVisible: (orderStatus === ORDER_STATUS.CREATED || orderStatus === ORDER_STATUS.CONFIRMED) && kpoCards.some(x => x.status === KPO_STATUS.APPROVED) && isSale,
      type: "GenerateConfirmation",
      promptTitle: `Wygeneruj potwierdzenie ${cardPromptForm} KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.APPROVED],
      submitBtnText: "Wygeneruj jako przekazujący",
    },
    {
      name: "Wygeneruj potwierdzenie (transportujący)",
      action: (kpoItems) => kpoActions.generateKpoCardsConfirmationAsCarrier({ orderId, kpoItems, performAll: false }),
      isVisible: (status === KPO_STATUS.APPROVED || status === KPO_STATUS.REJECTED) && isTransportInternal,
      isPerformAllVisible: (orderStatus === ORDER_STATUS.CREATED || orderStatus === ORDER_STATUS.CONFIRMED) && kpoCards.some(x => x.status === KPO_STATUS.APPROVED || x.status === KPO_STATUS.REJECTED) && isTransportInternal,
      type: "GenerateConfirmation",
      promptTitle: `Wygeneruj potwierdzenie ${cardPromptForm} KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.APPROVED, KPO_STATUS.TRANSPORT_CONFIRMATION],
      submitBtnText: "Wygeneruj",
      refreshTransportDetails: true
    },
    {
      name: "Potwierdź przyjęcie",
      action: (kpoItems) => kpoActions.assignKpoCardsReceiveConfirmation({ orderId, transactionType, kpoItems: kpoItems, performAll: false }),
      isVisible: status === KPO_STATUS.CONFIRMATION_GENERATED && isPurchase,
      isPerformAllVisible: orderStatus === ORDER_STATUS.COURSE_COMPLETED && kpoCards.some(x => x.status === KPO_STATUS.CONFIRMATION_GENERATED) && isPurchase,
      type: "ReceiveConfirmationForm",
      promptTitle: `Potwierdź przyjęcie ${cardPromptForm} KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.CONFIRMATION_GENERATED, KPO_STATUS.TRANSPORT_CONFIRMATION]
    },
    {
      name: "Potwierdź transport",
      action: (kpoItems) => kpoActions.changeKpoCardsStatusToTransportConfirmation({ orderId, transactionType, kpoIds: kpoItems ?? kpoIds, performAll: false }),
      isVisible: status === KPO_STATUS.RECEIVE_CONFIRMATION && isTransportInternal,
      isPerformAllVisible: orderStatus === ORDER_STATUS.RECEIVE_CONFIRMED && kpoCards.some(x => x.status === KPO_STATUS.RECEIVE_CONFIRMATION),
      type: "Prompt",
      promptTitle: `Potwierdź transport ${cardPromptForm} KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.RECEIVE_CONFIRMATION]
    },
    {
      name: "Wycofaj kartę",
      action: (kpoItems) => kpoActions.withdrawnKpoCards({ orderId, kpoItems, performAll: false }),
      isVisible: (status === KPO_STATUS.APPROVED || status === KPO_STATUS.CONFIRMATION_GENERATED) && isSale,
      isPerformAllVisible: kpoCards.some(x => x.status === KPO_STATUS.APPROVED || x.status === KPO_STATUS.CONFIRMATION_GENERATED) && isSale,
      type: "RemarksForm",
      promptTitle: performAll ? "Wycofaj karty KPO" : `Wycofaj kartę KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.APPROVED, KPO_STATUS.CONFIRMATION_GENERATED, KPO_STATUS.TRANSPORT_CONFIRMATION],
      submitBtnText: "Wycofaj"
    },
    {
      name: "Odrzuć",
      action: (kpoItems) => kpoActions.rejectKpoCards({ orderId, kpoItems, performAll: false }),
      isVisible: status === KPO_STATUS.CONFIRMATION_GENERATED && isPurchase,
      isPerformAllVisible: orderStatus === ORDER_STATUS.COURSE_COMPLETED && kpoCards.some(x => x.status === KPO_STATUS.CONFIRMATION_GENERATED) && isPurchase,
      type: "RejectForm",
      promptTitle: performAll ? "Odrzuć karty KPO" : `Odrzuć kartę KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.CONFIRMATION_GENERATED, KPO_STATUS.TRANSPORT_CONFIRMATION],
      submitBtnText: "Odrzuć"
    },
    {
      name: "Odrzuć na podstawie kwitu wagowego",
      action: (payload) => {
        const requestPayload = { orderId, kpoItems: payload.kpoItems, wastesDetails: payload.wastesDetails, performAll: false };
        return ordersActions.rejectKpoWithWastesDetails({ orderId, payload: requestPayload });
      },
      isVisible: status === KPO_STATUS.CONFIRMATION_GENERATED && isPurchase,
      isPerformAllVisible: orderStatus === ORDER_STATUS.COURSE_COMPLETED && kpoCards.some(x => x.status === KPO_STATUS.CONFIRMATION_GENERATED) && isPurchase,
      type: "OrderRejectForm",
      promptTitle: performAll ? "Odrzuć karty KPO z kwitem wagowym" : `Odrzuć kartę KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.CONFIRMATION_GENERATED, KPO_STATUS.TRANSPORT_CONFIRMATION],
      submitBtnText: "Odrzuć"
    },
    {
      name: "Dokonaj korekty",
      action: (kpoItems) => kpoActions.reviseKpoCards({ orderId, kpoItems: kpoItems, performAll: false }),
      isVisible: status === KPO_STATUS.REJECTED && isSale,
      isPerformAllVisible: orderStatus === ORDER_STATUS.COURSE_COMPLETED && kpoCards.some(x => x.status === KPO_STATUS.REJECTED) && isSale,
      type: "ReviseForm",
      promptTitle: `Dokonaj korekty ${cardPromptForm} KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.REJECTED]
    },
    {
      name: "Usuń",
      action: (kpoItems) => kpoActions.deleteKpoCards({ orderId, kpoIds: kpoItems, performAll: false }),
      isVisible: status === KPO_STATUS.PLANNED && isSale,
      isPerformAllVisible: orderStatus === ORDER_STATUS.CREATED && kpoCards.some(x => x.status === KPO_STATUS.PLANNED) && isSale,
      type: "Prompt",
      promptTitle: performAll ? `Usuń karty KPO` : `Usuń kartę KPO ${cardNumberPrompt}`,
      expectedStatus: [KPO_STATUS.PLANNED]
    },
    {
      name: "Pobierz potwierdzenie",
      action: () => kpoActions.downloadConfirmation({ kpoId, cardNumber: cardNumberQueryable }),
      isVisible: status !== KPO_STATUS.PLANNED && status !== KPO_STATUS.REJECTED && status !== KPO_STATUS.DELETED,
      isPerformAllVisible: false,
      isFileDownload: true,
      type: "DirectDropdown"
    },
    {
      name: "Pobierz wydruk",
      action: () => kpoActions.downloadPrintingPage({ kpoId, cardNumber: cardNumberQueryable }),
      isVisible: status !== KPO_STATUS.DELETED,
      isPerformAllVisible: false,
      isFileDownload: true,
      type: "DirectDropdown"
    },
    {
      name: "Zmień widoczność kart KPO kierowcy",
      action: (kpoItems) => ordersActions.updateOrderDriverExcludedKpoIds({ orderId, driverExcludedKpoIds: kpoItems }),
      isVisible: false,
      isPerformAllVisible: orderStatus === ORDER_STATUS.CREATED || orderStatus === ORDER_STATUS.CONFIRMED,
      type: "UpdateDriverExcludedKpoItems",
      promptTitle: "Zmień widoczność kart KPO kierowcy",
      submitBtnText: "Aktualizuj widoczność",
      expectedStatus: [KPO_STATUS.PLANNED, KPO_STATUS.APPROVED, KPO_STATUS.CONFIRMATION_GENERATED],
    }
  ];
}