import { FormModal } from "../../../_components/Forms/FormModal";
import { OrderProvider } from "../../../_components/Orders/MainForms/OrderContext";
import { TransportOrderDetails } from "../../../_components/Orders/TransportOrderDetails";
import { TRANSPORT_TYPES } from "../../../domain/TransportType";
import { TransportDetailsValidator } from "../../../domain/Orders/CreatePurchaseOrderValidator";
import * as Yup from "yup";
import { CardView } from "../../../_components/Abstractions/CardView";
import { TransactionTypeBadge } from "../../../_components/OrderViews/Components/TransactionTypeBadge";
import { OrderStatusBadge } from "../../../_components/OrderViews/Components/OrderStatusBadge";
import { TransportTypeBadge } from "../../../_components/OrderViews/Components/TransportTypeBadge";
import { VehicleTypeBadge } from "../../../_components/Vehicles/VehicleTypeBadge";
import { useDispatch, useSelector } from "react-redux";
import { mapToTransportDetails } from "../../../_services/Orders/orderMapper";
import { ordersActions } from "../../../_store/order.slice";
import { DomainButton } from "../../../_components/Abstractions/DomainButton";
import { BiMinus } from "react-icons/bi";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { Table } from "react-bootstrap";
import { forEach } from "react-bootstrap/ElementChildren";

export function UpdateOrderTransportDetailsBatch({ ordersIds, refreshCalendar, onDeselectedItem }) {
  const dispatch = useDispatch();
  const { driverView } = useSelector((x) => x.orderViews);
  const orders = driverView?.["NOT_ASSIGNED"]?.items?.filter((item) => ordersIds?.includes(item.id));
  const { showErrorMessage, showInfoMessage } = useToast();
  const defaultValues = {
    transportDetails: {
      transportType: TRANSPORT_TYPES.INTERNAL
    }
  }

  const validationSchema = Yup.object().shape({
    transportDetails: TransportDetailsValidator.getBasicValidator()
  });

  const formControls = <OrderProvider>
    <CardView headerText="Wybrane zlecenia" className="mb-2">
      <Table>
        <thead>
          <tr>
            <th>Nr. zlecenia</th>
            <th>Adres</th>
            <th>Typ transkacji</th>
            <th>Status</th>
            <th>Typ transportu</th>
            <th>Data załadunku</th>
            <th>Status</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
      {orders?.map(x => <tr key={x.id}>
        <td>{x.orderNumber}</td>
        <td>{x.addressName}</td>
        <td><TransactionTypeBadge transactionType={x.transactionType} /></td>
        <td><OrderStatusBadge status={x.status} /></td>
        <td><TransportTypeBadge transportType={x.transportType} /></td>
        <td>{x.orderDate}</td>
        <td><VehicleTypeBadge type={x.pickupType}/></td>
        <td><DomainButton onClick={() => onDeselectedItem(x.id)}><BiMinus/></DomainButton></td>
      </tr>)}
        </tbody>
      </Table>
    </CardView>
    <TransportOrderDetails isBatchUpdate />
  </OrderProvider>

  const onSubmit = async(payload) => {
    if(!orders || orders?.length === 0) {
      showErrorMessage("Nie wybrano zleceń do aktualizacji danych");
      return;
    }

    const transportDetails = mapToTransportDetails(payload);

    const command = {
      orderIds: orders?.map(x => x.id),
      transportDetails: {
        transportType: transportDetails.transportType,
        pickupType: transportDetails.pickupType,
        driverId: transportDetails.driverId,
        externalDriver: transportDetails.externalDriver,
        driverPhone: transportDetails.driverPhone,
        vehicleId: transportDetails.vehicleId,
        vehicleRegNumber: transportDetails.vehicleRegNumber,
        includedVehicleId: transportDetails.includedVehicleId,
        includedVehicleRegNumber: transportDetails.includedVehicleRegNumber,
        includedVehicleType: transportDetails.includedVehicleType,
        contractorTransportId: transportDetails.contractorTransportId,
        driverDate: transportDetails.driverDate,
        driverPriority: transportDetails.driverPriority
      }
    }

    const response = await dispatch(ordersActions.updateTransportDetailsBatch({ payload: command }));
    const { payload: commandPayload, error } = response || {};
    const { report } = commandPayload || {};
    let messageInfo = `Zaktualizowane zlecenia: ${report?.updatedItems?.length ?? 0}`;
    if(report?.updatedItems.length > 0) {
      report.updatedItems.forEach(x => onDeselectedItem(x))
      refreshCalendar();
    }

    if(report?.failedItems.length > 0) {
      messageInfo+=`\nNieudane aktualizacje: ${report?.failedItems.length}`;
      messageInfo+=`\nBłędy:\n ${report.errors.join("\n")}\n`;
    }

    if(report) {
      showInfoMessage(messageInfo);
    }

    if(error) {
      return response;
    }
    return null;
  }


  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        buttonText={`Grupowa zmiana transportu: ${orders?.length ?? ""}`}
        successMessage="Zaktualizowano dane transportu dla wybranych zleceń"
        title="Grupowa zmiana transportu"
        sizeModal="xl"
        btnSize="small"
        triggerBtnVariant="success"
        skipToast
        defaultValues={defaultValues}
        // onShowModal={onShow}
      />
    </>
  );
}