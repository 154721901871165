import { FormModal } from "../../../Forms/FormModal";
import { ValidateBdoAction } from "./BdoActionValidator";
import { BaseKpoForm } from "./KpoReceiveConfirmation";

export function UpdateDriverExcludedKpoItems({ kpoCards, onSubmit, name, promptTitle, performAll, submitBtnText }) {
  const { validate } = ValidateBdoAction();
  const defaultValues =  {
    kpoItems: kpoCards.map(x => ({
      ...x,
      wasteMass: x.wasteMass.toFixed(4),
      wasteCodeBdo: { id: x.wasteCodeId, codeDescription: x.codeDescription },
      isSelected: !x.isDriverExcluded
    }))
  };
  const formControls = <div>
    {kpoCards.map((x, idx) => <div key={idx}>
      <BaseKpoForm
        kpoItem={x}
        selectable={kpoCards?.length > 1}
        parentName={`kpoItems[${idx}]`}/>
    </div>)}
  </div>;

  const handleSubmit = async(payload) => {
    const kpoItems = payload.kpoItems.filter(x => !x.isSelected).map((x) => x.kpoId);
    const selectedKpoItems = payload.kpoItems.filter(x => x.isSelected);
    validate(selectedKpoItems);
    return await onSubmit(kpoItems);
  };

  return <FormModal
    title={promptTitle}
    formControls={formControls}
    defaultValues={defaultValues}
    onSubmit={handleSubmit}
    buttonText={name}
    // validationSchema={validationSchema}
    isButtonTrigger={performAll}
    sizeModal="md"
    skipToast
    submitBtnText={submitBtnText}/>;
}