export const useKpoReportMessageProvider = () => {
  const getUpdateKpoReportMessage = (response) => {
    if (response.error) {
      return response.error?.message;
    }

    const { payload } = response;
    if(!payload?.report) {
      return "Zaktualizowano karty KPO";
    }

    const { orderChangeStatusReport, succeedItems, skippedItems, failedItems } = payload.report;
    const { isSucceeded: isOrderStatusChanged, errorMessage: orderChangeStatusErrorMessage } = orderChangeStatusReport || {};
    let message = "Dane w BDO zostały zaktualizowane: \n";

    if (succeedItems.length > 0) {
      const succeedMessage = succeedItems.map(x => x.cardNumber ?? "Brak nr. (Planowana)").join(",\n");
      message += `Zaktualizowane karty:\n ${succeedMessage}\n`;
    }

    if (skippedItems.length > 0) {
      const skippedMessage = skippedItems.map(x => `${x.cardNumber ?? "Brak nr. (Planowana)"} - ${x.error}`).join(",\n");
      message += `Pominięta aktualizacja:\n ${skippedMessage}\n`;
    }

    if (failedItems.length > 0) {
      const failedMessage = failedItems.map(x => `${x.cardNumber ?? "Brak nr. (Planowana)"} - ${x.error}`).join(",\n");
      message += `Nieudane aktualizacje:\n ${failedMessage}\n`;
    }

    if (isOrderStatusChanged) {
      message += "Status zlecenia: zaktualizowany";
    } else {
      message += `Status zlecenia: niezmieniony. ${orderChangeStatusErrorMessage ?? ""}`;
    }

    return message;
  };

  return { getUpdateKpoReportMessage };
};