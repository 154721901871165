import { Link, useNavigate, useParams } from "react-router-dom";
import { useGetOrderDetails } from "../useGetOrderDetails";
import { Card } from "react-bootstrap";
import { TransactionTypeBadge } from "../Components/TransactionTypeBadge";
import { HorizontalView } from "../../Abstractions/HorizontalView";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";
import { KpoBdoStatusBadge } from "../../Bdo/Kpo/KpoBdoStatusBadge";
import { OrderTransportDetailsView } from "./OrderTransportDetailsView";
import { OrderStatusBadge } from "../Components/OrderStatusBadge";
import { RefreshKpoCards } from "../../../useCases/orders/refreshKpoCards/RefreshKpoCards";
import { KpoBdoCardDetailsOffcanvas } from "../../Bdo/Kpo/KpoBdoCardDetailsOffcanvas";
import { RefreshKpoCard } from "../../../useCases/orders/refreshKpoCards/RefreshKpoCard";
import { OrderKpoCardActions } from "./OrderKpoCardActions";
import { stringToDate } from "../../../domain/Configuration";
import { OrderWorkflowService } from "./Actions/OrderWorkflowService";
import { KpoLinkFromOrder, KpoUnlinkFromOrder } from "./Actions/KpoUnlinkFromOrder";
import { OrderHistoryView } from "./OrderHistoryView";
import { LoadingSpinner } from "../../Abstractions/LoadingSpinner";
import { TransportTypeBadge } from "../Components/TransportTypeBadge";
import { WastesOrderDetailsView } from "./WastesOrderDetailsView";
import { UpdateOrderMainDetails } from "../../../useCases/orders/updateMainDetails/UpdateOrderMainDetails";
import { UpdateBdoDetails } from "../../../useCases/orders/updateBdoDetails/UpdateBdoDetails";
import { BackButton } from "../../Abstractions/BackButton";
import { OrderFilesView } from "./OrderFilesView";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { GenerateOrderKpoCards } from "../../../useCases/orders/updateBdoDetails/GenerateOrderKpoCards";
import { OrderSeriesBadge } from "../Components/OrderSeriesBadge";
import { OrderDriverActionsDetailsView } from "./OrderDriverActionsDetailsView";
import { KpoDriverExclude, KpoDriverInclude } from "./Actions/KpoDriverExclude";

export function OrderDetailsView() {
  const { id } = useParams()
  const navigate = useNavigate();
  const { item } = useGetOrderDetails({ id });
  const { orderBdoInfo, contractorDetails, transportDetails, transactionType, wastesDetails, driverActionsDetails } = item || {};
  const {  vehicleRegNumber, includedVehicleRegNumber, plannedLoadingDate } = transportDetails || {};
  const { isBdoIntegrated } = wastesDetails || {};

  const mainDetails = <Card className="h-100">
    <Card.Header className="d-flex align-items-center justify-content-between">Główne dane <UpdateOrderMainDetails order={item}/>
    </Card.Header>
    <Card.Body>
      {transactionType !== "Transport" && <>
        <OrderLineItem name="Kontrahent"><Link to={`/contractors/${contractorDetails?.contractorId ?? ""}`}>{contractorDetails?.contractorName}</Link></OrderLineItem>
        <OrderLineItem name="Uwagi kontrahenta">{contractorDetails?.contractorRemarks}</OrderLineItem>
        <OrderLineItem name="Nazwa adresu">{contractorDetails?.addressName}</OrderLineItem>
        <OrderLineItem name="Adres">{contractorDetails?.addressRaw}</OrderLineItem>
        <OrderLineItem name="Uwagi adresu kontrahenta" skipIfEmpty>{contractorDetails?.addressRemarks}</OrderLineItem>
      </>}
      {transactionType === "Transport" && <>
        <OrderLineItem name="Przekazujący">{contractorDetails?.contractorName}</OrderLineItem>
        <OrderLineItem name="Uwagi przekazującego">{contractorDetails?.contractorRemarks}</OrderLineItem>
        <OrderLineItem name="Nazwa adresu przekazującego">{contractorDetails?.addressName}</OrderLineItem>
        <OrderLineItem name="Adres przekazującego">{contractorDetails?.addressRaw}</OrderLineItem>
        <OrderLineItem name="Uwagi adresu przekazującego" skipIfEmpty>{contractorDetails?.addressRemarks}</OrderLineItem>

        <OrderLineItem name="Przejmujący">{contractorDetails?.additionalContractorName}</OrderLineItem>
        <OrderLineItem name="Nazwa adresu przejmującego">{contractorDetails?.additionalAddressName}</OrderLineItem>
        <OrderLineItem name="Adres przejmującego">{contractorDetails?.additionalAddressRaw}</OrderLineItem>
        <OrderLineItem name="Uwagi adresu przejmującego" skipIfEmpty>{contractorDetails?.additionalAddressRemarks}</OrderLineItem>
      </>}
      <OrderLineItem name="Osoba kontaktowa">{contractorDetails?.contactPersonInfo ?? "-"}</OrderLineItem>
      <OrderLineItem name="Przedstawiciel handlowy" skipIfEmpty>{contractorDetails?.salesRepresentativeInfo}</OrderLineItem>
      <OrderLineItem name="Pośrednik">{contractorDetails?.intermediaryName ?? "-"}</OrderLineItem>
      {transactionType === "Sale" && <OrderLineItem name="Numer zamówienia przejmującego">{contractorDetails?.externalOrderNumber ?? "-"}</OrderLineItem>}
      <OrderLineItem name="Uwagi do zlecenia">{contractorDetails?.remarks ?? "-"}</OrderLineItem>
    </Card.Body>
  </Card>;

  const wasteDetailsCard = <WastesOrderDetailsView item={item} />
  const vehiclesBdoFormatted = vehicleRegNumber ? `${vehicleRegNumber}${includedVehicleRegNumber ? `/ ${includedVehicleRegNumber}` : ""}` : null;

  const orderDataBdo = {
    orderId: id,
    orderStatus: item?.status,
    transactionType: item?.transactionType,
    transportType: transportDetails?.transportType,
    vehiclesBdoFormatted,
    plannedTransportTime: plannedLoadingDate,
    intermediaryName: item?.contractorDetails?.intermediaryName,
    intermediaryBdoRegistrationNumber: item?.contractorDetails?.intermediaryBdoRegistrationNumber
  };

  const bdoDetailsCard = <Card className="h-100">
    <Card.Header>Dane BDO</Card.Header>
    <Card.Body>
      {orderDataBdo?.orderStatus !== ORDER_STATUS.CLOSED && orderDataBdo?.orderStatus !== ORDER_STATUS.CANCELLED && <div className="d-flex mb-1">
        <UpdateBdoDetails order={item} />
        {transactionType === "Sale" && <GenerateOrderKpoCards order={item}/>}
      </div>}
      {orderBdoInfo?.kpoCards.length > 0 && <Card.Header>Karty KPO</Card.Header>}
      {orderDataBdo?.orderStatus !== ORDER_STATUS.CLOSED && <div className="d-flex my-1">
        {orderBdoInfo?.kpoCards.length > 0 && <>
        <RefreshKpoCards orderId={item.id} transactionType={item.transactionType} orderNumber={item.orderNumber}/>
        <OrderKpoCardActions orderData={orderDataBdo} kpoItem={{}} kpoCards={orderBdoInfo?.kpoCards} performAll/>
        </>}
      </div>}
      <div className="d-flex gap-2 flex-wrap mt-1 upper">
        {orderBdoInfo?.kpoCards?.map(x => <Card key={x.kpoId}>
          <Card.Header><b>{x.cardNumber ?? "00000/0000/KPO/0000/000000000"}</b></Card.Header>
          <Card.Body>
            {/*<LoadingSpinner isLoading={orderBdoInfo?.loading} as='div' center/>*/}
            <div className="d-flex justify-content-between align-items-start">
              <Card.Text><BadgeDomain>{x.wasteCode}</BadgeDomain></Card.Text>
              <div className="d-flex">
                <RefreshKpoCard orderId={item.id} kpoId={x.kpoId} transactionType={item.transactionType} cardNumber={x.cardNumber}/>
                <KpoBdoCardDetailsOffcanvas item={x}/>
                <KpoLinkFromOrder orderId={item.id} kpoItem={x} orderNumber={item.orderNumber}/>
                <KpoUnlinkFromOrder orderId={item.id} kpoItem={x} orderNumber={item.orderNumber}/>
                <KpoDriverInclude orderId={item.id} kpoItem={x} kpoItems={orderBdoInfo.kpoCards}/>
                <KpoDriverExclude orderId={item.id} kpoItem={x} kpoItems={orderBdoInfo.kpoCards}/>
                <OrderKpoCardActions orderData={orderDataBdo} kpoItem={x} performAll={false} kpoCards={[x]}/>
              </div>
            </div>
            <Card.Text>Masa: {x.wasteMass.toFixed(4)} [Mg]</Card.Text>
            <Card.Text>
              {<KpoBdoStatusBadge status={x.status}/>}
              {x.isRevised && <BadgeDomain bg="orange">Korekta {x.revisionNumber}</BadgeDomain>}
            </Card.Text>
            <Card.Text>Nr rejestracyjny: <BadgeDomain bg="success">{x.vehicleRegNumber}</BadgeDomain></Card.Text>
            <Card.Text><b>Planowana</b> / <b>rzeczywista</b> data transportu</Card.Text>
            <Card.Text><b className={x.isPlannedTransportTimeEligible ? "" : "error"}>{stringToDate(x.plannedTransportTime)}</b> / <b className={x.isRealTransportTimeEligible ? "" : "error"}>{stringToDate(x.realTransportTime)}</b> </Card.Text>
            {x.additionalInfo && <Card.Text>Dodatkowe informacje: <i><b>{x.additionalInfo}</b></i></Card.Text>}
            <Card.Text>Uwagi: <i>{x.remarks ?? "-"}</i></Card.Text>
          </Card.Body>
        </Card>)}
      </div>
    </Card.Body>
  </Card>;

  return <div className="m-3">
    <h2>
    <BackButton className="me-3" size="medium" btnSize="lg">Powrót</BackButton>
      Szczegóły zlecenia{" "}
      <TransactionTypeBadge transactionType={item?.transactionType} size="x-large"/>
      {item.isSeries ? <OrderSeriesBadge className="" size="x-large" onClick={() => navigate(`/orders/series/${item?.orderSeriesId}`)} status={item?.status}/> : ""}
    </h2>
    <Card>
      <Card.Body style={{ fontSize: "small" }}>
        <div className="d-flex w-100">
        </div>
        <Card.Header className="d-flex align-items-center justify-content-between mb-1">
          <div className="d-flex gap-3">
            <Card.Title>Numer zlecenia: <b>{item?.orderNumber}</b></Card.Title>
            <Card.Title>
              Typ transportu: <TransportTypeBadge transportType={transportDetails?.transportType}/>
            </Card.Title>
            <Card.Title>Status biuro:{" "}
              <LoadingSpinner isLoading={item?.status?.isLoading}/>
              {!item.isDeleted ? <OrderStatusBadge status={item?.status}/> : <BadgeDomain bg="danger">Usunięte</BadgeDomain>}
            </Card.Title>
             <OrderDriverActionsDetailsView orderStatus={item?.status} driverActionsDetails={driverActionsDetails}/>
          </div>
          <OrderWorkflowService orderId={id} orderNumber={item?.orderNumber} status={item?.status} isBdoIntegrated={isBdoIntegrated} isDeleted={item?.isDeleted} wasKpoConfirmationGenerated={item?.orderBdoInfo?.wasKpoConfirmationGenerated} />
        </Card.Header>

        {item?.loading && <div className="spinner-border"/>}
        {!item?.loading && <HorizontalView>
          {mainDetails}
          <OrderTransportDetailsView item={item}/>
          {wasteDetailsCard}
        </HorizontalView>}
        <OrderFilesView item={item}/>
        {isBdoIntegrated && bdoDetailsCard}
        <OrderHistoryView history={item?.history} cancellationReason={item?.cancellationReason}/>
      </Card.Body>
    </Card>
  </div>;
}

export const OrderLineItem = ({ name, children }) => <Card.Text>{name}: <b className="upper">{children}</b></Card.Text>;