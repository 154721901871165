import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";

export function UndoConfirmOrder({ orderId, orderNumber }) {
  const successMessage = "Rozpoczęcie kursu zostało cofnięte";
  const buttonText = "Confij rozpoczęcie kursu";
  const title = `Czy cofnąć rozpoczęcie kursu ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.undoConfirmOrder,
    orderViewAction: orderViewsActions.undoConfirm,
    successMessage,
    buttonText,
    title
  };

  return <OrderWorkFlowAction {...props} />;
}