import * as Yup from "yup";
import { WASTE_DIFFERENCE_REASON } from "../WasteDifferenceReason";
import moment from "moment";

export class TransportDetailsValidator {
  static getBasicValidator = () => {
    return Yup.object().shape({
      driverId: Yup.object().nullable().shape({
        driverRemarks: Yup.string().nullable().max(250, "Uwagi dla kierowcy mogą mieć maksymalnie 250 znaków")
      }),
      transportType: Yup.string().required("Rodzaj transportu jest wymagany").typeError("Rodzaj transportu jest wymagany"),
      pickupType: Yup.string().required("Typ pojazdu jest wymagany").typeError("Typ pojazdu jest wymagany"),
    })
  }

  static getValidator = (transactionTypeParam, isUpdate = false) => {
    const minimumDate = new Date();
    minimumDate.setMinutes(minimumDate.getMinutes() - 5);

    return Yup.object().shape({
      invoiceTransportType: Yup.string().nullable().when(["transportType", "isTransportInvoiceNeeded"], {
        is: (transportType, isTransportInvoiceNeeded) => transportType === "External" && isTransportInvoiceNeeded === true && transactionTypeParam === "Sale",
        then: Yup.string().required("Informacja na kogo ma być faktura jest wymagana").typeError("Informacja na kogo ma być faktura jest wymagana")
      }),

      plannedLoadingDate: Yup.date().when("isPlannedLoadingDateChange", {
        is: (isPlannedLoadingDateChange) => isPlannedLoadingDateChange || !isUpdate,
        then: Yup.date().typeError("Data załadunku jest wymagana").min(minimumDate, "Data załadunku nie może być wcześniejsza niż dzisiejsza")
      }),
      plannedReloadingDate: Yup.date().nullable().when([], {
        is: () => transactionTypeParam === "Transport" && !isUpdate,
        then: Yup.date().typeError("Data rozładunku jest wymagana").min(minimumDate, "Data rozładunku nie może być wcześniejsza niż dzisiejsza")
      }),
      driverDate: Yup.string().nullable().test("driverDate-vs-plannedLoadingDate", "Data kierowcy powinna być późniejsza od daty załadunku", function(value) {
        const { plannedLoadingDate } = this.parent;
        if (!value) {
          return true;
        }
        const driverDate = moment(value, "YYYY-MM-DD").startOf("day");
        const loadingDate = moment(plannedLoadingDate, "YYYY-MM-DD").startOf("day");
        return driverDate.isSameOrAfter(loadingDate);
      }),
      shippingDate: Yup.string().nullable().test("shippingDate-vs-plannedLoadingDate", "Data awizacji nie może być wcześniejsza od daty załadunku", function(value) {
        const { plannedLoadingDate, isShippingDateFromPast } = this.parent;
        if (!value || isShippingDateFromPast) {
          return true;
        }
        const shippingDate = moment(value, "YYYY-MM-DD").startOf("day");
        const loadingDate = moment(plannedLoadingDate, "YYYY-MM-DD").startOf("day");
        return shippingDate.isSameOrAfter(loadingDate);
      }),
      plannedShippingTimeStart: Yup.string().nullable().when("shippingDate", {
        is: (shippingDate) => shippingDate && transactionTypeParam !== "Transport",
        then: Yup.string()
        .nullable()
        .required("Czas awizacji jest wymagany przy podawaniu daty awizacji")
        .test("plannedShippingTimeStartMin", "Czas awizacji może być ustawiony najwcześniej na 6:00", function(value) {
          return moment(value, "HH:mm").isSameOrAfter(moment("06:00", "HH:mm"));
        })
        .test("plannedShippingTimeStart-vs-plannedLoadingDate", "Czas awizacji powinien być późniejszy od czasu załadunku", function(value) {
          const { plannedLoadingDate, shippingDate, isShippingDateFromPast } = this.parent;
          if(!shippingDate || !value || isShippingDateFromPast){
            return true;
          }
          const dateTimeString = `${shippingDate} ${value}`;
          const shippingDateTime = moment(dateTimeString, "YYYY-MM-DD, HH:mm");
            return moment(shippingDateTime).isSameOrAfter(moment(plannedLoadingDate));
        })
      }),
      plannedShippingTimeEnd: Yup.string().nullable().when("shippingDate", {
        is: (shippingDate) => shippingDate && transactionTypeParam !== "Transport",
        then: Yup.string()
        .nullable()
        .required("Czas awizacji jest wymagany przy podawaniu daty awizacji")
        .test("plannedShippingTimeEndMax", "Czas awizacji może być ustawiony najpóźniej na 18:00", function(value) {
          return moment(value, "HH:mm").isSameOrBefore(moment("18:00", "HH:mm"));
        })
        .test("is-greater", "Niepoprawny zakres czasu awizacji, wyjazd wcześniejszy od przyjazdu", function(value) {
          const { plannedShippingTimeStart } = this.parent;
          return moment(value, "HH:mm").isSameOrAfter(moment(plannedShippingTimeStart, "HH:mm"));
        })
      }),
      externalIncludedVehicleType: Yup.string().nullable().when(["includedVehicleRegNumber", "pickupType", "transportType" ], {
        is: (includedVehicleRegNumber, pickupType, transportType) =>
          includedVehicleRegNumber && pickupType === "Tir" && transportType === "External",
        then: Yup.string().required("Typ naczepy jest wymagany dla obcej naczepy").typeError("Typ naczepy jest wymagany dla obcej naczepy")
      }),
      squarePlaceType: Yup.string().nullable().when("shippingDate", {
        is: (shippingDate) => shippingDate && transactionTypeParam !== "Transport",
        then: Yup.string().required("Miejsce na placu jest wymagane przy podawaniu daty awizacji").typeError("Miejsce na placu jest wymagane przy podawaniu daty awizacji")
      }),
      freightForwarder: Yup.object().nullable().when("isFreightForwarder", {
        is: true,
        then: Yup.object().typeError("Spedycja jest wymagana")
      }),
      // transportContractor: Yup.object().nullable().when("transportType", {
      //   is: "External",
      //   then: Yup.object().typeError("Przewoźnik jest wymagany")
      // }),
      transferPurchases: Yup.array().nullable().when("isTransfer", {
        is: (isTransfer) => isTransfer && transactionTypeParam === "Sale",
        then: Yup.array().min(1, "Zakup z przerzutu jest wymagany").typeError("Zakup z przerzutu jest wymagany")
      }),
      externalDriverPhone: Yup.string().nullable().when([ "transportType", "externalDriver" ], {
        is: (transportType, externalDriver) => transportType === "External" && !!externalDriver,
        then: Yup.string().nullable().required("Telefon do kierowcy obcego jest wymagany")
      }),
    }).concat(TransportDetailsValidator.getBasicValidator());
  };
}

export class WasteMassValidator {
  static validator = Yup.string().required("Masa odpadu jest wymagana").typeError("Masa odpadu jest wymagana").test("empty-check", "Masa odpadów musi być różna od zera", wasteMass => wasteMass !==
    "0" && wasteMass !== "0.0000").test("is-greater-than-30", "Masa nie może być większa niż 30 [Mg]", value => {
    const numericValue = parseFloat(value);
    return numericValue <= 30;
  }).matches(/^\d+(\.\d{4})?$|^\d+(,\d{4})?$/, "Masa musi być wpisana z dokładnością do 4 miejsc po przecinku");

  static wasteSchema = Yup.object().shape({ wasteMass: WasteMassValidator.validator });

  static saleExtendedWasteSchema = Yup.object().nullable().shape({
    revenueDocumentNumber: Yup.string().nullable().when("revenueWasteMass", {
      is: (x) => x != 0, then: Yup.string().nullable().required("Numer PW / RW jest wymagany") }),
    initWasteMass: Yup.number().nullable().required("Masa początkowa jest wymagana").typeError("Masa początkowa jest wymagana").min(0.0001, "Masa początkowa musi być większa od zera"),
    revenueWasteMass: Yup.number().nullable().typeError("Masa PW / RW jest wymagana").required("Masa PW / RW jest wymagana"),
    wasteDifferenceReasons: Yup.array().nullable().when("revenueWasteMass", {
      is: (x) => x != 0, then: Yup.array().nullable().min(1, "Przyczyny są wymagane") }),
    wasteDifferenceOtherReason: Yup.string().nullable().when("wasteDifferenceReasons", {
      is: (x) => x?.includes(WASTE_DIFFERENCE_REASON.OTHER),
      then: Yup.string().nullable().required("Inne przyczyny są wymagane")
    })
  });

  static saleExtendedWastesSchema = Yup.array().of(WasteMassValidator.saleExtendedWasteSchema);

  static saleExtendedDetailsValidator = Yup.object().shape({
    saleWastes: Yup.object().nullable().shape({
      wastes: WasteMassValidator.saleExtendedWastesSchema
    })
  });

  static wastesSchema = Yup.array().of(WasteMassValidator.wasteSchema);

  static containerExchangeSchema = Yup.object().shape({
    usedContainer: Yup.object().typeError("Kontener do wymiany jest wymagany").required("Kontener do wymiany jest wymagany"),
    wastes: WasteMassValidator.wastesSchema //.min(1, "Wymagany jest przynajmniej jeden towar")
  });
  static wasteDetailsValidator = Yup.object().shape({
    loading: Yup.object().nullable().when(["isLoading", "requireWasteMass"], {
      is: (isLoading, requireWasteMass) => isLoading && requireWasteMass,
      then: Yup.object().shape({
        wastes: WasteMassValidator.wastesSchema
      })
    }),
    containers: Yup.array().nullable().when(["isContainerExchange", "requireWasteMass"], {
      is: (isContainerExchange, requireWasteMass) => isContainerExchange && requireWasteMass,
      then: Yup.array().of(WasteMassValidator.containerExchangeSchema)
    }),
    saleWastes: Yup.object().nullable().shape({
      wastes: WasteMassValidator.wastesSchema
    })
  });
}

class CreatePurchaseValidator {
  static wasteSchema = Yup.object().shape({
    wasteMass: WasteMassValidator.validator
  });

  static validationSchema = Yup.object().shape({
    wastesDetails: Yup.object().shape({
      loading: Yup.object().shape({
        wastes: Yup.array().of(CreatePurchaseValidator.wasteSchema)
      }),
      requireWasteMass: false
    })
  });
}

export class OrderValidator {
  static validationSchema = Yup.object().shape({
    contractor: Yup.object().typeError("Kontrahent jest wymagany"),
    receiverContractor: Yup.object().nullable().when("transactionType", {
      is: "Transport",
      then: Yup.object().typeError("Kontrahent przejmujący jest wymagany")
    }),
    orderAddress: Yup.object().typeError("Adres jest wymagany"),
    receiverOrderAddress: Yup.object().nullable().when("transactionType", {
      is: "Transport",
      then: Yup.object().typeError("Adres rozładunku jest wymagany")
    }),
    transactionType: Yup.string().required("Typ transakcji jest wymagany"),
  })
}

export default CreatePurchaseValidator;