import Dropdown from "react-bootstrap/Dropdown";
import { KpoRemarksForm } from "./KpoRemarksForm";
import { KpoReviseForm } from "./KpoReviseForm";
import { KpoReceiveConfirmation } from "./KpoReceiveConfirmation";
import { KpoGenerateConfirmation } from "./KpoGenerateConfirmation";
import { KpoCardNumberList } from "./KpoCardNumberList";
import { KpoRejectForm } from "./KpoRejectForm";
import { FormModal } from "../../../Forms/FormModal";
import { ValidateBdoAction } from "./BdoActionValidator";
import { OrderRejectForm } from "./OrderRejectForm";
import { UpdatePlannedOrApprovedKpoCards } from "./UpdatePlannedOrApprovedKpoCards";
import { UpdateDriverExcludedKpoItems } from "./UpdateDriverExcludedKpoItems";

export class bdoActionModalFactory {
  static getPromptModal = ({ handleAction, kpoCards, item, idx, performAll, expectedStatus }) => {
    const filteredKpoCards = kpoCards?.filter(x => expectedStatus?.includes(x.status)).map(x => ({ ...x, isSelected: true }));
    const defaultValues = { kpoItems: filteredKpoCards };
    const { validate } = ValidateBdoAction();

    const handleOnSubmit = async(payload) =>  {
      const kpoIds = payload.kpoItems.filter(x => x.isSelected).map(x => x.kpoId);
      validate(kpoIds);

      return await handleAction({ action: item.action, kpoItems: kpoIds });
    }

    return <FormModal
      key={idx}
      title={item.promptTitle}
      submitBtnText={item.name}
      buttonText={item.name}
      sizeModal="md"
      isButtonTrigger={performAll}
      defaultValues={defaultValues}
      formControls={<KpoCardNumberList kpoCards={filteredKpoCards} selectable={true} /> }
      onSubmit={handleOnSubmit}
    />;
  };

  static getDirectDropdownItem = ({ handleAction, item, idx }) => <Dropdown.Item
    key={idx}
    className="domain-control"
    onClick={() => handleAction(item)}>
    {item.name}
  </Dropdown.Item>;

  static getRemarksModal = ({ handleAction, kpoCards, item, idx, performAll, expectedStatus }) =>
    <KpoRemarksForm
      key={idx}
      kpoCards={kpoCards}
      onSubmit={(payload) => handleAction({ action: item.action, kpoItems: payload.kpoItems })}
      name={item.submitBtnText ?? item.name}
      promptTitle={item.promptTitle}
      performAll={performAll}
      expectedStatus={expectedStatus}
      submitBtnText={item.submitBtnText} />;

  static getReviseModal = ({ handleAction, kpoCards, item, idx, performAll }) =>
    <KpoReviseForm
      key={idx}
      kpoCards={kpoCards}
      onSubmit={(kpoItems) => handleAction({ action: item.action, kpoItems: kpoItems })}
      name={item.name}
      promptTitle={item.promptTitle}
      performAll={performAll} />;

  static getReceiveConfirmationModal = ({ handleAction, kpoCards, item, idx, performAll, orderData }) =>
    <KpoReceiveConfirmation
      key={idx}
      kpoCards={kpoCards}
      onSubmit={(payload) => handleAction({ action: item.action, kpoItems: payload.kpoItems })}
      name={item.name}
      promptTitle={item.promptTitle}
      performAll={performAll}
      submitBtnText={item.submitBtnText}
      intermediaryName={orderData?.intermediaryName}
      intermediaryBdoRegistrationNumber={orderData?.intermediaryBdoRegistrationNumber}
    />;

  static getGenerateConfirmationModal = ({ handleAction, kpoCards, item, idx, performAll, orderData }) => {
    return <KpoGenerateConfirmation
      key={idx}
      transactionType={orderData?.transactionType}
      kpoCards={kpoCards}
      onSubmit={(kpoItems) => handleAction({ action: item.action, kpoItems: kpoItems, refreshTransportDetails: true })}
      name={item.name}
      promptTitle={item.promptTitle}
      vehiclesBdoFormatted={orderData?.vehiclesBdoFormatted}
      plannedTransportTime={orderData?.plannedTransportTime}
      performAll={performAll}
      submitBtnText={item.submitBtnText}
    />;
  };

  static getRejectKpoModal = ({ handleAction, kpoCards, item, idx, performAll }) => {
    return <KpoRejectForm
      key={idx}
      kpoCards={kpoCards}
      onSubmit={(kpoItems) => handleAction({ action: item.action, kpoItems: kpoItems })}
      name={item.name}
      promptTitle={item.promptTitle}
      performAll={performAll}
      submitBtnText={item.submitBtnText}/>;
  };

  static getOrderRejectModal = ({ handleAction, kpoCards, item, idx, performAll, orderData }) => {
    return <OrderRejectForm
      key={idx}
      orderId={orderData?.orderId}
      kpoCards={kpoCards}
      onSubmit={(kpoItems) => handleAction({ action: item.action, kpoItems: kpoItems })}
      name={item.name}
      promptTitle={item.promptTitle}
      performAll={performAll}
      submitBtnText={item.submitBtnText}/>;
  };

  static getUpdatePlannedOrApprovedKpoCardsModal = ({ handleAction, kpoCards, item, idx, performAll, orderData }) => {
    return <UpdatePlannedOrApprovedKpoCards
      key={idx}
      transactionType={orderData?.transactionType}
      kpoCards={kpoCards}
      onSubmit={(kpoItems) => handleAction({ action: item.action, kpoItems: kpoItems })}
      name={item.name}
      promptTitle={item.promptTitle}
      vehiclesBdoFormatted={orderData?.vehiclesBdoFormatted}
      plannedTransportTime={orderData?.plannedTransportTime}
      performAll={performAll}
      submitBtnText={item.submitBtnText}
    />;
  };

  static getUpdateDriverExcludedKpoItemsModal = ({ handleAction, kpoCards, item, idx, performAll }) => {
    return <UpdateDriverExcludedKpoItems
      key={idx}
      kpoCards={kpoCards}
      onSubmit={(kpoItems) => handleAction({ action: item.action, kpoItems: kpoItems })}
      name={item.name}
      promptTitle={item.promptTitle}
      performAll={performAll}
      submitBtnText={item.submitBtnText}
    />;
  };
}