import { useDispatch } from "react-redux";
import { useToast } from "../../../../_helpers/notifications/useToasts";
import { orderViewsActions } from "../../../../_store/orderView.slice";
import { PromptModal } from "../../../Abstractions/PromptModal";
import { ordersActions } from "../../../../_store/order.slice";
import { Truck } from "react-bootstrap-icons";

export function KpoDriverExclude({ orderId, kpoItem, kpoItems }) {
  const { kpoId, cardNumber, isDriverExcluded } = kpoItem;
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  if (isDriverExcluded) {
    return;
  }

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const driverExcludedKpoIds = kpoItems.filter(x => x.isDriverExcluded || x.kpoId === kpoId).map(x => x.kpoId) ?? [];
    var response = await dispatch(ordersActions.updateOrderDriverExcludedKpoIds({ orderId, driverExcludedKpoIds  }));
    if (!response.error) {
      await dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      // await dispatch(orderViewsActions.refreshHistory({ id: orderId }));
      showSuccessMessage("Odłączono kartę KPO z widoku kierowcy");
    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={<Truck/>}
        btnVariant="dodgerblue"
        title={`Czy odłączyć kartę KPO ${cardNumber ?? ""} z widoku kierowcy?`}
        type="YesNo"
        onSubmit={onSubmit}
        btnSize="sm"
      />
    </>
  );
}

export function KpoDriverInclude({ orderId, kpoItem, kpoItems }) {
  const { kpoId, cardNumber, isDriverExcluded } = kpoItem;
  const dispatch = useDispatch();
  const { showSuccessMessage, showErrorMessage } = useToast();

  if (!isDriverExcluded) {
    return;
  }

  async function onSubmit(promptResult) {
    if (promptResult !== "Yes") {
      return;
    }

    const driverExcludedKpoIds = kpoItems.filter(x => x.isDriverExcluded && x.kpoId !== kpoId).map(x => x.kpoId) ?? [];
    var response = await dispatch(ordersActions.updateOrderDriverExcludedKpoIds({ orderId, driverExcludedKpoIds  }));
    if (!response.error) {
      await dispatch(orderViewsActions.getBdoInfoById({ id: orderId }));
      showSuccessMessage("Podpięto kartę KPO do widoku kierowcy");
      // dispatch(orderViewsActions.linkKpoCard({ kpoId }));

    } else {
      showErrorMessage(response.error?.message);
    }
  }

  return (
    <>
      <PromptModal
        buttonText={<Truck/>}
        btnVariant="warning"
        title={`Czy dołączyć kartę KPO ${cardNumber ?? ""} do widoku kierowcy?`}
        type="YesNo"
        onSubmit={onSubmit}
        btnSize="sm"
      />
    </>
  );
}

