import { useDispatch } from "react-redux";
import * as Yup from "yup";
import { authActions } from "../../_store";
import { FormControl } from "../../_components/Forms/FormControl";
import { FormModal } from "../../_components/Forms/FormModal";

export function ResetPassword({ userId, login }) {
  const dispatch = useDispatch();

  const validationSchema = Yup.object().shape({
    newPassword: Yup.string().required("Nowe hasło jest wymagane").min(8, "Nowe hasło musi mieć przynajmniej 8 znaków"),
    confirmNewPassword: Yup.string().required("Potwierdzenie nowego hasła jest wymagane").oneOf([Yup.ref("newPassword"), null], "Nowe hasła muszą się zgadzać")
  });

  async function onSubmit(payload) {
    return await dispatch(authActions.resetPassword({ userId, payload }));
  }

  const formControls = (
    <>
      <FormControl label="Nowe hasło" name="newPassword" inputType="password" className="login-input"/>
      <FormControl
        label="Potwierdź nowe hasło"
        name="confirmNewPassword"
        className="login-input"
        inputType="password"
      />
    </>
  );

  const buttonText = "Reset hasła";
  const title = `Resetuj hasło dla użytkownika '${login}'`;
  const successMessage = "Zmieniono hasło";

  return (
    <>
      <FormModal
        formControls={formControls}
        validationSchema={validationSchema}
        onSubmit={onSubmit}
        successMessage={successMessage}
        buttonText={buttonText}
        title={title}
      />
    </>
  );
}