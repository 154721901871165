import { FormControl } from "_components/Forms/FormControl";
import { HorizontalView } from "../Abstractions/HorizontalView";
import { DriverSelect } from "../Drivers/DriverSelect";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { VehicleSelect } from "../Vehicles/VehicleSelect";
import { SemiTrailerType, VEHICLES_TYPES } from "../../domain/VehicleType";
import { ContractorSelect } from "../Contractors/ContractorSelect";
import { Dropdown } from "../Abstractions/Dropdown";
import { OrderDriverPriority } from "../../domain/Orders/OrderDriverPriority";
import { OrderContext } from "./MainForms/OrderContext";

export const InternalVehicles = ({ getFieldName, pickupType, driverId, readonly, isUpdate = false }) => {
  const { watch, setValue } = useFormContext();
  let includedVehicleType = null;
  let includedVehicleName = null;
  const vehicleField = getFieldName("vehicleId");
  const includedVehicleField = getFieldName("includedVehicleId");
  const includedVehicleSemiTrailerTypeField = includedVehicleField + ".semiTrailerType";
  const mainVehicle = watch(vehicleField);
  const includedVehicle = watch(includedVehicleField);

  switch (pickupType) {
    case "Hook":
      includedVehicleType = "Trailer";
      includedVehicleName = "Naczepa";
      break;
    case "Tir":
      includedVehicleType = "SemiTrailer";
      includedVehicleName = "Przyczepa";
      break;
    default:
      includedVehicleName = "Przyczepa / naczepa";
      break;
  }

  const vehicleLabel = pickupType
    ? `${VEHICLES_TYPES[pickupType]} Eko-Partner`
    : "Samochód Eko-Partner";

  const includedVehicleLabel = includedVehicleType
    ? `${VEHICLES_TYPES[includedVehicleType]} Eko-Partner`
    : `${includedVehicleName} Eko-Partner`;

  useEffect(() => {
    setValue(includedVehicleSemiTrailerTypeField, null);
    setValue(includedVehicleField, null);
    setValue(vehicleField, null);
  }, [pickupType]);

  const columnsSize = isUpdate ? null : [4, 4];

  return (
    <HorizontalView columnsSize={columnsSize}>
      <VehicleSelect
        name={vehicleField}
        vehicleType={pickupType} driverId={driverId} label={vehicleLabel}
        required={false} readonly={readonly}
        allowEmptyQuery={false}
        selectedItem={mainVehicle}
      />
      <VehicleSelect
        name={includedVehicleField}
        vehicleType={includedVehicleType}
        driverId={driverId}
        label={includedVehicleLabel}
        required={false}
        readonly={readonly}
        readonlyWhenNoItems
        allowEmptyQuery
        selectedItem={includedVehicle}
      />
      {pickupType === "Tir" && <Dropdown name={includedVehicleSemiTrailerTypeField} label="Typ naczepy" items={SemiTrailerType} required={false} readonly/>}
    </HorizontalView>
  );
};

export const ExternalVehicles = ({ getFieldName, readonly }) => {
  const { watch } = useFormContext();
  const shouldGenerateKpo = watch("wastesDetails.generateKpoCards");
  const includedVehicleRegNumber = watch(getFieldName("includedVehicleRegNumber"));
  return (
    <HorizontalView>
      <ContractorSelect name={getFieldName("transportContractor")} contractorType="Carrier" label="Przewoźnik" readonly={readonly} required={shouldGenerateKpo || false}/>
      <FormControl name={getFieldName("vehicleRegNumber")} label="Nr. rejestracyjny pojazdu" required={false} readonly={readonly}/>
      <FormControl name={getFieldName("includedVehicleRegNumber")} label="Nr. rejestracyjny naczepy" required={false} readonly={readonly}/>
      <Dropdown name={getFieldName("externalIncludedVehicleType")} label="Typ naczepy" items={SemiTrailerType} required={includedVehicleRegNumber} readonly={readonly}/>
    </HorizontalView>);
};

export const OrderExternalDriver = ({ getFieldName, readonly }) => {
  const { watch } = useFormContext();
  const externalDriver = watch(getFieldName("externalDriver"));

  return (<HorizontalView >
    <FormControl name={getFieldName("externalDriver")} label="Kierowca obcy" readonly={readonly} required={false}/>
    <FormControl name={getFieldName("externalDriverPhone")} label="Telefon do kierowcy" required={!!externalDriver}/>
    <></>
  </HorizontalView>);
};

export const OrderInternalDriver = ({ getFieldName, pickupType, readonly,isBatchUpdate }) => {
  const fieldName = getFieldName("driverId");
  const phoneFieldName = getFieldName("driverId.phone");
  const driverRemarksFieldName = getFieldName("driverId.driverRemarks");
  const driverDateFieldName = getFieldName("driverDate");
  const driverPriorityFieldName = getFieldName("driverPriority");
  const { setValue, getValues } = useFormContext();
  const driver = getValues(fieldName);

  useEffect(() => {
    if (driver?.type !== pickupType) {
      setValue(phoneFieldName, null);
      setValue(fieldName, null);
    }
  }, [pickupType]);

  return <>
    <HorizontalView>
      <DriverSelect name={fieldName} driverType={pickupType} readonly={readonly}/>
      <FormControl name={phoneFieldName} label="Telefon do kierowcy" readonly required={!!driver}/>
      {!isBatchUpdate && <FormControl name={driverRemarksFieldName} label="Uwagi dla kierowcy" required={false} readonly={readonly}/>}
    </HorizontalView>
    <HorizontalView>
      <FormControl name={driverDateFieldName} label="Data zlecenia kierowcy" required={false} inputType="date" readonly={readonly}/>
      <Dropdown name={driverPriorityFieldName} label="Priorytet zlecenia kierowcy" items={OrderDriverPriority} required={false} readonly={readonly}/>
      <div/>
    </HorizontalView>
  </>;
};
