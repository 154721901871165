import { useSelector } from "react-redux";
import { useCalendarView } from "../OrderCalendar/useCalendarView";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderCalendarFilterBox } from "../OrderCalendar/OrderCalendarFilterBasic";
import { Card } from "react-bootstrap";
import { BadgeDomain } from "../../Abstractions/BadgeDomain";

import { CalendarDay } from "../OrderCalendar/CalendarDay";
import { UpdateOrderTransportDetailsBatch } from "../../../useCases/orders/updateTransportDetails/UpdateOrderTransportDetailsBatch";
import { BasicSwitch } from "../../Abstractions/BasicSwitch";
import { useState } from "react";

export function OrderDriverView() {
  const { driverView, driverViewDates } = useSelector((x) => x.orderViews);
  const {
    refreshCalendar, currentFilters, handleCalendarChange,
    refreshFilters, setCurrentFilters,
    selectedItems, onSelectedItem, onDeselectedItem}
    = useCalendarView({ calendarViewDates: driverViewDates, orderViewsAction: orderViewsActions.getDriverView });

  const { weekDates, currentDate } = driverViewDates;
  return <div className="d-flex flex-column justify-content-center">
    <h2>Widok kierowców ({weekDates?.[0].date} - {weekDates?.[weekDates.length - 1].date})</h2>
    <OrderCalendarFilterBox
      currentDate={currentDate}
      currentFilters={currentFilters}
      handleCalendarChange={handleCalendarChange}
      handleFilterChange={refreshFilters}
      refreshCalendar={refreshCalendar}
      setCurrentFilters={setCurrentFilters}
    />
    <DriverCalendarItems driverView={driverView} refreshCalendar={refreshCalendar} selectedItems={selectedItems} onSelectedItem={onSelectedItem} onDeselectedItem={onDeselectedItem}/>
  </div>;
}

export function DriverCalendarItems({ driverView, refreshCalendar, selectedItems, onSelectedItem, onDeselectedItem  }) {
  const [selectable, setSelectable] = useState(false);
  return <div className="d-flex flex-column align-items-start justify-content-start gap-2 p-3">
    {driverView?.loading && <div className="spinner-border"></div>}
    {Object.keys(driverView)?.map((driverId) => {
      const { driverName, items } = driverView[driverId];
      const isSelectable = driverName === "Nieprzypisani";
      return <Card key={driverId} className="w-100">
        <Card.Header as="h5" className="d-flex align-items-center flex-row gap-2 upper dark-grey white-text">
          <BadgeDomain size="small">{items?.length}</BadgeDomain>
          {driverName}
          {isSelectable && <div className="d-flex ms-4 gap-4 align-items-end">
          <BasicSwitch name="selectable" label="Wybór zleceń do grupowej zmiany" value={selectable} onChange={setSelectable} skipFormMargin/>
          {selectable && <UpdateOrderTransportDetailsBatch ordersIds={selectedItems} refreshCalendar={refreshCalendar} onDeselectedItem={onDeselectedItem}/>}
          </div>}
        </Card.Header>
        <div className={`d-flex flex-row gap-1 flex-wrap`}>
          {items?.map(x => <CalendarDay
            key={x.id}
            orderItem={x}
            selectable={isSelectable && selectable}
            onSelect={() => onSelectedItem?.(x.id)}
            onDeselect={() => onDeselectedItem?.(x.id)}
            isSelected={isSelectable && selectable && selectedItems?.some(item => item === x.id)}
          />)}
        </div>
      </Card>;
    })}
  </div>;
}
