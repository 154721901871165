import { useEffect, useState } from "react";
import { getDatesForWeek } from "../../../_helpers/dateExtensions";
import { useSearchParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useToast } from "../../../_helpers/notifications/useToasts";
import { ORDER_STATUS } from "../../../domain/Orders/OrderStatus";
import { TRANSPORT_TYPES } from "../../../domain/TransportType";
import { PICKUP_TYPES } from "../../../domain/VehicleType";

export function useCalendarView({ calendarViewDates, orderViewsAction, skipDates = false, selectable = false }) {
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedItems, setSelectedItems] = useState([]);
  const queryValues = [...searchParams.entries()].reduce((acc, [key, value]) =>
    ({
      ...acc, [key]: value
    }), {});
  const getQueryString = (values) => values?.join("|") ?? "";

  const defaultStatuses= [ ORDER_STATUS.CREATED, ORDER_STATUS.CONFIRMED ];
  const defaultTransportTypes= [ TRANSPORT_TYPES.INTERNAL, TRANSPORT_TYPES.EXTERNAL ];
  const defaultPickupTypes= [ PICKUP_TYPES.HOOK, PICKUP_TYPES.TIR ];
  const defaultValues = {
    orderStatuses: !skipDates ? getQueryString(defaultStatuses) : "",
    includeOverdue: !skipDates,
    transportTypes: getQueryString(defaultTransportTypes),
    pickupTypes: getQueryString(defaultPickupTypes),
  }

  const [currentFilters, setCurrentFilters] = useState({
    ...defaultValues,
    ...queryValues
  });
  const dispatch = useDispatch();
  const { showErrorMessage } = useToast();

  const refreshCalendar = async(dates) => {
    const params = window.location.search === "" ? null : window.location.search;
    const response =
      await dispatch(orderViewsAction({ dates: dates ?? calendarViewDates, params }));

    if (response && response.error) {
      showErrorMessage(response.error.message);
    }
  };

  const refreshFilters = async(filters, dates) => {
    let newFilters;
    const fieldName = Object.keys(filters)[0];
    if (!filters[fieldName]) {
      const { [fieldName]: _, ...omittedFilters } = currentFilters;
      newFilters = omittedFilters;
    } else {
      newFilters = { ...currentFilters, ...filters };
    }

    setCurrentFilters(newFilters);
    setSearchParams(newFilters);

    await refreshCalendar(dates);
  };

  const handleCalendarChange = async(dates, filters) => {
    const dateFilters = { "dateFrom": dates.dateFrom, "dateTo": dates.dateTo, ...filters };
    await refreshFilters(dateFilters, dates);
  };

  const initView = async(filters) => {
    if (!skipDates) {
      const dateFrom = searchParams.get("dateFrom");
      const dates = getDatesForWeek(true, dateFrom);
      await handleCalendarChange(dates);
    }
  }

  useEffect(() => {
    initView();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if(!currentFilters) {
      initView(defaultValues);
    }
  }, [currentFilters]);

  const onSelectedItem = (orderId) => {
    setSelectedItems(x => [...x, orderId]);
  }

  const onDeselectedItem = (orderId) => {
    setSelectedItems(x => x.filter(item => item !== orderId));
  }

  return { refreshCalendar, currentFilters, handleCalendarChange, refreshFilters, setCurrentFilters, selectedItems, onSelectedItem, onDeselectedItem };
}