import { useEffect, useState } from "react";

export function TableDomainRow({
  columns,
  item,
  onSelect,
  onUnselect,
  isSelected,
  selectable = true,
  getRowClassName = null
}) {
  const [selected, setSelected] = useState(false);
  const className = getRowClassName?.(item);
  const handleClick = () => {
    const newState = !selected;
    if (selectable) {
      setSelected(newState);
    }

    if (!newState && onUnselect) {
      onUnselect(item);
    } else if (newState && onSelect) {
      onSelect(item);
    }

    if(!selectable) {
      onSelect?.(item);
    }
  };

  useEffect(() => {
    setSelected(isSelected);
  }, [isSelected]);

  return (
    <>
      <tr className={selected ? "table-primary" : className} onClick={handleClick}>
        {columns.map((x, idx) => {
            if (x.map) {
              const mappedValue = x.map(item);
              return <td key={idx}>{mappedValue}</td>;
            }
            return <td key={idx}>{item[x.name]}</td>;
          }
        )}
      </tr>
    </>
  );
}
