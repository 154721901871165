import { Table } from "react-bootstrap";
import { TableDomainRow } from "./TableDomainRow";
import { useEffect, useState } from "react";
import { DomainPagination } from "./DomainPagination";

export function TableDomain({
  keyColumn,
  columns,
  items,
  onSelect,
  onUnselect,
  onSelectedItemsChanged,
  multiSelect,
  selectedItems,
  paging,
  handlePageChange,
  bordered,
  selectable = true,
  isAllSelected = false,
  isSelectedRow,
  getRowClassName
}) {
  const [selected, setSelected] = useState([]);
  const onSelectItem = (item) => {
    if(!selectable) {
      return;
    }

    if (multiSelect) {
      if (!selected.some((x) => x[keyColumn] === item[keyColumn])) {
        const newItems = [...selected, item];
        setSelected(newItems);
        onSelectedItemsChanged(newItems);
        return;
      }
    }
    onSelect(item);
  };

  const onUnselectItem = (item) => {
    if (!multiSelect) {
      return;
    }

    const newSelected = selected.filter(
      (x) => x[keyColumn] !== item[keyColumn]
    );
    setSelected(newSelected);

    onSelectedItemsChanged(newSelected);
    onUnselect?.(item);
  };

  useEffect(() => {
    if (selectedItems && selectedItems !== selected) {
      setSelected(selectedItems);
    }
  }, [selectedItems]);

  const TableDefinition = (
    <thead>
    <tr>
      {columns.map((x, idx) => (
        <th key={idx}>{x.headerName}</th>
      ))}
    </tr>
    </thead>
  );

  const GetTableItem = (item) => {
    return (
      <TableDomainRow
        key={item[keyColumn]}
        columns={columns}
        item={item}
        onSelect={onSelectItem}
        onUnselect={onUnselectItem}
        multiSelect
        isSelected={
          multiSelect
            ? selected?.some(x => x[keyColumn] === item[keyColumn])
            : isSelectedRow?.(item)
        }
        selectable={selectable}
        getRowClassName={getRowClassName}
      />
    );
  };

  const Body = (
    <tbody>
    {items?.loading && (
      <>
        <tr className="spinner-border"></tr>
      </>
    )}
    {items?.length > 0 && items?.map((x) => GetTableItem(x))}
    </tbody>
  );

  const Pagination = paging && !items.loading && (
    <DomainPagination
      onPageChange={handlePageChange}
      paging={paging}
      itemsCount={items.length}
    />
  );

  return (
    <>
      <Table bordered={bordered} hover>
        {TableDefinition}
        {Body}
      </Table>
      {Pagination}
    </>
  );
}
