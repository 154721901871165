import { FormControl } from "_components/Forms/FormControl";
import { DomainSwitch } from "_components/Abstractions/DomainSwitch";
import { Row } from "react-bootstrap";
import { useFormContext } from "react-hook-form";
import { OrderExternalDriver, OrderInternalDriver, InternalVehicles, ExternalVehicles } from "./OrderFormComponents";
import { Dropdown } from "_components/Abstractions/Dropdown";
import { TransportType } from "domain/TransportType";
import { PickupTypes } from "domain/VehicleType";
import { useContext, useEffect } from "react";
import { HorizontalView } from "_components/Abstractions/HorizontalView";
import { InvoiceTransportType } from "../../domain/InvoiceTransportType";
import { ContractorSelect } from "../Contractors/ContractorSelect";
import { OrderMultiSelect } from "../OrderViews/Components/OrderSelect";
import { OrderContext } from "./MainForms/OrderContext";
import { SquarePlaceType } from "../../domain/SquarePlaceType";
import { OrderShippingTimeSelect } from "../OrderViews/ShippingCalendar/OrderShippingTimeSelect";
import { NumericControl } from "../Forms/NumericControl";
import { Hr } from "../Abstractions/Hr/Hr";

export function TransportOrderDetails({ orderId, transactionType, isMainDetailsUpdateAvailable = true, isSeries, isBatchUpdate = false }) {
  const GetFieldName = (field) => `transportDetails.${field}`;
  const { watch, setValue } = useFormContext();
  const { isUpdate } = useContext(OrderContext);
  const transportType = watch(GetFieldName("transportType"));
  const isTransportInvoiceNeeded = watch(GetFieldName("isTransportInvoiceNeeded"));
  const isFreightForwarder = watch(GetFieldName("isFreightForwarder"));
  const pickupType = watch(GetFieldName("pickupType"));
  const driver = watch(GetFieldName("driverId"));
  const isTransfer = watch(GetFieldName("isTransfer"));
  const isPlannedLoadingDateChange = watch(GetFieldName("isPlannedLoadingDateChange"));
  const shippingDate = watch(GetFieldName("shippingDate"));
  const isTirPickup = pickupType === "Tir";
  const isTransport = transactionType === "Transport";
  const isSale = transactionType === "Sale";
  const isEkoTransport = transportType === "Internal";
  const isClientOwnTransport = transportType === "External";

  useEffect(() => {
    if (transportType === "External") {
      setValue(GetFieldName("pickupType"), "Tir");
      if (!isFreightForwarder) {
        setValue(GetFieldName("invoiceTransportType"), "Carrier");
      }
    }
  }, [transportType]);

  useEffect(() => {
    if (!isFreightForwarder && transportType === "External") {
      setValue(GetFieldName("invoiceTransportType"), "Carrier");
    }
  }, [isFreightForwarder]);

  // useEffect(() => {
  //   if(isTransportInvoiceNeeded && transportType === "Internal"){
  //       console.log(isTransportInvoiceNeeded);
  //     // setValue(GetFieldName("invoiceTransportType"), null);
  //   }
  // }, [isTransportInvoiceNeeded]);

  useEffect(() => {
    const refreshTransferPurchaseOrder = () => {
      // const transferPurchaseId = getValues("transportDetails.transferPurchaseId");
      // if (transferPurchaseId) {
        // const { payload } = await dispatch(orderViewsActions.getOrderBasicInfo({ id: transferPurchaseId }));
        // const { display } = payload;
      if(isTransfer) {
        // setValue(GetFieldName("transferPurchase"), [{ id: transferPurchaseId, displayName: display }]);
        setValue("transportDetails.transferPurchases", null);
        setValue("transportDetails.isFullyTransfer", true);
      }
    };

    refreshTransferPurchaseOrder();

  }, [isTransfer]);

  return (<>
    <div className="d-flex gap-3">
      {isUpdate && isMainDetailsUpdateAvailable && !isSeries && <DomainSwitch name={GetFieldName("isPlannedLoadingDateChange")} label="Zmiana daty załadunku"/>}
    </div>
    {!isBatchUpdate &&
    <HorizontalView>
      <FormControl name={GetFieldName("plannedLoadingDate")} label="Planowana data i godzina załadunku" value="dd.MM.yyyy" inputType="datetime-local"
                   readonly={(!isPlannedLoadingDateChange && isUpdate) || !isMainDetailsUpdateAvailable}/>
      {isTransport && (<FormControl name={GetFieldName("plannedReloadingDate")} label="Planowana data rozładunku" inputType="datetime-local"/>)}
    </HorizontalView>}
    <HorizontalView>
      <Dropdown label="Rodzaj transportu" name={GetFieldName("transportType")} items={TransportType} readonly={!isMainDetailsUpdateAvailable}/>
      <Dropdown items={PickupTypes} name={GetFieldName("pickupType")} label="Typ pojazdu" readonly={isClientOwnTransport || !isMainDetailsUpdateAvailable}/>
      <></>
    </HorizontalView>
    <>
      {isEkoTransport && <OrderInternalDriver getFieldName={GetFieldName} pickupType={pickupType} readonly={!isMainDetailsUpdateAvailable || !pickupType} isBatchUpdate={isBatchUpdate}/>}
      {isClientOwnTransport && <OrderExternalDriver getFieldName={GetFieldName} readonly={!isMainDetailsUpdateAvailable}/>}
    </>
    {isEkoTransport && <InternalVehicles getFieldName={GetFieldName} pickupType={pickupType} driverId={driver?.id} readonly={!isMainDetailsUpdateAvailable}/>}
    {isClientOwnTransport && <ExternalVehicles getFieldName={GetFieldName} readonly={!isMainDetailsUpdateAvailable} isUpdate={isUpdate}/>}
    {isTirPickup && !isTransport && !isSeries && !isBatchUpdate && (<>
      <div className="mb-3">
        <OrderShippingTimeSelect date={shippingDate} selectedId={orderId}/>
      </div>
      {isUpdate && <DomainSwitch name={GetFieldName("isShippingDateFromPast")} label="Wcześniejsza data awizacji"/>}
      <HorizontalView>
        <FormControl name={GetFieldName("shippingDate")} label="Data awizacji" inputType="date" required={false}/>
        <FormControl name={GetFieldName("plannedShippingTimeStart")} label="Czas awizacji - przyjazd" inputType="time" required={shippingDate}/>
        <FormControl name={GetFieldName("plannedShippingTimeEnd")} label="Czas awizacji - wyjazd" inputType="time" required={shippingDate}/>
        <Dropdown name={GetFieldName("squarePlaceType")} label="Miejsce na placu" items={SquarePlaceType} required={shippingDate}/>
      </HorizontalView>
    </>)}
    {!isBatchUpdate &&
    <Row>
      <div className="d-flex gap-4">
        <DomainSwitch name={GetFieldName("isTransportInvoiceNeeded")} label="Faktura za transport"/>
        {!isTransport && <DomainSwitch name={GetFieldName("isTransfer")} label="Przerzut"/>}
        {isTransfer && isSale && (<DomainSwitch name={GetFieldName("isFullyTransfer")} label="Pełny przerzut"/>)}
        {isClientOwnTransport && (<DomainSwitch name={GetFieldName("isFreightForwarder")} label="Spedycja"/>)}
      </div>
      {isSale && isTransfer && <div className="mb-2">
        <OrderMultiSelect name={GetFieldName("transferPurchases")} transactionType="Purchase" label="Zakupy z przerzutu" isTransfer={true}/>
      </div>}
      {isFreightForwarder && <ContractorSelect contractorType="Carrier" name={GetFieldName("freightForwarder")} label="Spedycja"/>}
      {isTransportInvoiceNeeded && (<>
        <Hr>Dane faktury</Hr>
        {isClientOwnTransport &&
          <Dropdown name={GetFieldName("invoiceTransportType")} label="Na kogo faktura (przewoźnik / spedycja)" items={InvoiceTransportType} readonly={!isFreightForwarder}/>}
        <HorizontalView>
          <FormControl name={GetFieldName("transportInvoiceNumber")} label="Numer FV za transport" required={false}/>
          <NumericControl name={GetFieldName("transportInvoiceAmount")} label="Stawka FV za transport [zł]" required={false}/>
        </HorizontalView>
      </>)}
    </Row>}
  </>);
}
