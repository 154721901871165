export const OrderDriverPriority = {
  None: "Normalny",
  High: "Wysoki",
  Urgent: "Pilny",
}

export const OrderDriverPriorityColors = {
  None: "success",
  High: "warning",
  Urgent: "dark",
}