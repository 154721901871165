import { useEffect, useRef, useState } from "react";
import { CloseButton, ListGroup } from "react-bootstrap";
import { BiSearch } from "react-icons/bi";

import s from "./style.module.css";
import { useFormContext } from "react-hook-form";
import { StringExtensions } from "_helpers/extensions";
import { ControlLabel } from "../ControlLabel";

export function SelectSearcher({
  name,
  label,
  items,
  optionsObject,
  defaultOptionsObject,
  onSearchChange,
  placeholder,
  saveWholeObject,
  readonly = false,
  required = true
}) {
  const [showOptions, setShowOptions] = useState(false);
  const [selectedOption, setSelectedOption] = useState(null);
  const [display, setDisplay] = useState("");
  const [searchTerm, setSearchTerm] = useState("");
  const [searchTimeout, setSearchTimeout] = useState(null);
  const { getFieldState } = useFormContext();
  const { key, value } = optionsObject;
  const fieldState = getFieldState(name);
  const { error } = fieldState;
  const { setValue, getValues, watch, clearErrors } = useFormContext();
  const formValue = watch(name);
  const formattedLabel = <ControlLabel label={label} required={required}/>

  const areValuesChanged = () => {
    if (!saveWholeObject && formValue === selectedOption) {
      return false;
    }

    if (saveWholeObject && JSON.stringify(formValue) === JSON.stringify(selectedOption)) {
      return false;
    }

    if (saveWholeObject && formValue?.[key] === selectedOption?.[key] && formValue?.[value] === display) {
      return false;
    }

    return true;
  };

  useEffect(() => {
    const defaultValue = getValues(name);

    if (defaultValue?.length > 0 || defaultValue) {
      if (saveWholeObject) {
        setSelectedOption(defaultValue);
        return;
      }

      const parentObjectName =
        StringExtensions.extractObjectBeforeLastProperty(name);

      const parentObject = getValues(parentObjectName);

      const defaultValues = {
        [key]: parentObject?.[key],
        [value]: parentObject?.[value]
      };

      setSelectedOption(defaultValues);
    }
  }, []);

  useEffect(() => {
    if (!areValuesChanged()) {
      return;
    }

    if (!saveWholeObject) {
      return;
    }

    if (!formValue && selectedOption) {
      setDisplay("");
    }

    if (formValue && display === "") {
      setSelectedOption(formValue);
    }

  }, [formValue]);

  useEffect(() => {
    if (!areValuesChanged()) {
      return;
    }

    const setValueOptions = {
      shouldValidate: false,
      shouldTouch: true,
      shouldDirty: true
    };
    if (selectedOption) {
      const newValue = saveWholeObject ? selectedOption : selectedOption?.[key];

      setValue(name, newValue, setValueOptions);
      setDisplay(selectedOption?.[value] ?? "");
      clearErrors(name);
    } else //if(display !== "" ||formValue)
    {
      setValue(name, null);
      setDisplay("");
    }
  }, [selectedOption]);

  const handleSearch = (event) => {
    if (event.target.value === searchTerm) {
      return;
    }
    setSearchTerm(event.target.value);

    if (searchTimeout) {
      clearTimeout(searchTimeout);
    }

    const newSearchTimeout = setTimeout(() => {
      onSearchChange?.(event.target.value);
    }, 300);

    setSearchTimeout(newSearchTimeout);
  };

  const handleSelectOption = (option) => {
    setShowOptions(false);
    setSelectedOption(option);
  };

  const handleDismiss = (e) => {
    e.stopPropagation();
    setShowOptions(false);
    setSelectedOption(null);
  };

  const handleClick = () => {
    if (showOptions) {
     ref.current?.focus();
    }
  };
  const ref = useRef(null);

  const searcher = (
    <div className={s.searcher}>
      <input
        className="form-control domain-control"
        ref={ref}
        value={searchTerm}
        onChange={handleSearch}
        placeholder={placeholder}
      />
      <BiSearch className={s.searcherIcon}/>
    </div>
  );

  const handleOptionsShow = () => {
    if(readonly) {
      return;
    }

    const isEmptySelect = searchTerm === "" && items?.length === 0;
    if (isEmptySelect) {
      onSearchChange(" ");
    }
   setShowOptions(true);
  };

  const selectedSection = (
    <>
      {
        <div className={"form-floating"}>
          <input
            className={`form-control form-select hover-pointer form-input ${
              error ? "is-invalid" : ""
            }`}
            placeholder={placeholder}
            readOnly
            value={display}
            onClick={() => {
              handleClick();
            }}
            onFocus={handleOptionsShow}
            disabled={readonly}
          />
          <div className={s.actions}>
            {display?.length > 1 && (
              <CloseButton disabled={readonly} tabIndex={2} onClick={handleDismiss}/>
            )}
            <div className="vr" style={{ height: "24px" }}></div>
          </div>
          <div className="invalid-feedback">{error?.message}</div>
          <div className="invalid-feedback">{error?.[key]?.message}</div>
          <label>{formattedLabel}</label>
        </div>
      }
    </>
  );

  const optionsSection = (
    <div className={s.options}>
      <ListGroup>
        {items?.length > 0 &&
          items.map((option) => (
            <ListGroup.Item key={option[optionsObject.key]} onMouseDown={() => handleSelectOption(option)} className={s.option}>
              {option[optionsObject.value]}
            </ListGroup.Item>
          ))}
        {(items?.length === 0 && searchTerm?.length === 0 &&
          <ListGroup.Item>Zacznij wyszukiwać...</ListGroup.Item>
        )}
      </ListGroup>
    </div>
  );

  return (
    <>
      <div
        className={`${s.mainContainer} mb-3`}
        onClick={handleOptionsShow}
        onBlur={() => setShowOptions(false)}
      >
        {selectedSection}
        {showOptions && searcher}
        {showOptions && optionsSection}
      </div>
    </>
  );
}
