import { ordersActions } from "../../../_store/order.slice";
import { orderViewsActions } from "../../../_store/orderView.slice";
import { OrderWorkFlowAction } from "./OrderWorkFlowAction";

export function CompleteCourseOrder({ orderId, orderNumber }) {
  const successMessage = "Kurs został zrealizowany";
  const buttonText = "Zrealizuj kurs";
  const title = `Czy zrealizować kurs ${orderNumber}?`;

  const props = {
    orderId: orderId,
    orderAction: ordersActions.completeCourse,
    orderViewAction: orderViewsActions.completeCourse,
    successMessage,
    buttonText,
    title
  };

  return <OrderWorkFlowAction {...props} />
}

