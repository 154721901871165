import { ConfirmOrder } from "../../../../useCases/orders/workflowActions/ConfirmOrder";
import { CompleteCourseOrder } from "../../../../useCases/orders/workflowActions/CompleteCourseOrder";
import { ConfirmOrderReceive } from "../../../../useCases/orders/workflowActions/ConfirmOrderReceive";
import { ConfirmOrderTransport } from "../../../../useCases/orders/workflowActions/ConfirmOrderTransport";
import { CloseOrder } from "../../../../useCases/orders/workflowActions/CloseOrder";
import { CancelOrder } from "../../../../useCases/orders/workflowActions/CancelOrder";
import { RejectOrder } from "../../../../useCases/orders/workflowActions/RejectOrder";
import { ORDER_STATUS } from "../../../../domain/Orders/OrderStatus";
import { ReopenOrder } from "../../../../useCases/orders/workflowActions/ReopenOrder";
import { useSelector } from "react-redux";
import { USER_ROLES } from "../../../../domain/UserRoles";
import { DeleteOrder } from "../../../../useCases/orders/workflowActions/DeleteOrder";
import { RecoverOrder } from "../../../../useCases/orders/workflowActions/RecoverOrder";
import { UndoConfirmOrder } from "../../../../useCases/orders/workflowActions/UndoConfirmOrder";

export function OrderWorkflowService({ orderId, orderNumber, transactionType, status, isBdoIntegrated, wasKpoConfirmationGenerated, isDeleted = false }) {
  const details = useSelector((x) => x.auth.details);
  const isSell = transactionType === "Sale";
  const isStatus = (expectedStatus) => status === expectedStatus;
  const actions = [
    { name: "confirm", action: ConfirmOrder, isVisible: isStatus(ORDER_STATUS.CREATED) },
    { name: "completeCourse", action: CompleteCourseOrder, isVisible: isStatus(ORDER_STATUS.CONFIRMED) },
    { name: "undoConfirmOrder", action: UndoConfirmOrder, isVisible: isStatus(ORDER_STATUS.CONFIRMED) && !wasKpoConfirmationGenerated },
    { name: "confirmReceive", action: ConfirmOrderReceive, isVisible: isStatus(ORDER_STATUS.COURSE_COMPLETED) && isBdoIntegrated } ,
    { name: "rejectOrder", action: RejectOrder, isVisible: isStatus(ORDER_STATUS.COURSE_COMPLETED) && isBdoIntegrated && isSell } ,
    { name: "confirmTransport", action: ConfirmOrderTransport, isVisible: isStatus(ORDER_STATUS.RECEIVE_CONFIRMED) && isBdoIntegrated } ,
    {
      name: "closeOrder",
      action: CloseOrder,
      isVisible: (isStatus(ORDER_STATUS.TRANSPORT_CONFIRMED) && isBdoIntegrated) || (isStatus(ORDER_STATUS.COURSE_COMPLETED) && !isBdoIntegrated) },
    {
      name: "cancelOrder",
      action: CancelOrder,
      isVisible: !isStatus(ORDER_STATUS.CANCELLED) &&
        !isStatus(ORDER_STATUS.RECEIVE_CONFIRMED) &&
        !isStatus(ORDER_STATUS.TRANSPORT_CONFIRMED) &&
        !isStatus(ORDER_STATUS.CLOSED)
    },
    {
      name: "reopenOrder",
      action: ReopenOrder,
      isVisible:
        isStatus(ORDER_STATUS.CLOSED) && (details.role === USER_ROLES.ADMIN || details.role === USER_ROLES.SUPER_USER)
    },
    {
      name: "deleteOrder",
      action: DeleteOrder,
      isVisible: isStatus(ORDER_STATUS.CREATED),
    },
    { name: "recoverOrder", action: RecoverOrder, isVisible: false, isVisibleForDeleted: true },
  ]

  const availableActions = actions.filter(x => (x.isVisible && !isDeleted) || (isDeleted && x.isVisibleForDeleted));

  return <div>
    {availableActions.map(x => {
      const Action = x.action;
      return <Action key={x.name} orderId={orderId} orderNumber={orderNumber} userRole={details?.role}/>;
    })}
  </div>;
}