import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";

import { fetchWrapper } from "_helpers";
import { defaultPageNumber, defaultPageSize } from "_store";
import { ORDER_STATUS } from "../domain/Orders/OrderStatus";

// create slice

const name = "orderViews";
const initialState = createInitialState();
const reducers = createReducers();
const extraActions = createExtraActions();
const extraReducers = createExtraReducers();
const slice = createSlice({ name, initialState, reducers, extraReducers });

// exports

export const orderViewsActions = { ...slice.actions, ...extraActions };
export const orderViewsReducer = slice.reducer;

// implementation

function createInitialState() {
  return {
    ordersListView: {},
    ordersCalendarView: {},
    ordersDeletedListView: {},
    calendarViewDates: {},
    vehicleViewDates: {},
    vehicleView: {},
    driverViewDates: {},
    driverView: {},
    orderDetails: {},
    orderSeriesDetails: {},
    shippingCalendarView: {},
    orderSeriesListView: {}
  };
}

function createReducers() {
  return {
    confirm,
    completeCourse,
    confirmReceive,
    confirmTransport,
    close,
    cancel,
    reject,
    unlinkKpoCard,
    linkKpoCard,
    reopen,
    recover,
    undoConfirm
  };

  function confirm(state) {
    state.orderDetails.status = ORDER_STATUS.CONFIRMED;
  }

  function completeCourse(state) {
    state.orderDetails.status = ORDER_STATUS.COURSE_COMPLETED;
  }

  function confirmReceive(state) {
    state.orderDetails.status = ORDER_STATUS.RECEIVE_CONFIRMED;
  }

  function confirmTransport(state) {
    state.orderDetails.status = ORDER_STATUS.TRANSPORT_CONFIRMED;
  }

  function close(state) {
    state.orderDetails.status = ORDER_STATUS.CLOSED;
  }

  function cancel(state) {
    state.orderDetails.status = ORDER_STATUS.CANCELLED;
  }

  function reject(state) {
    state.orderDetails.status = ORDER_STATUS.REJECTED;
  }

  function reopen(state) {
    state.orderDetails.isReopened = true;
    state.orderDetails.status = state.orderDetails.wastesDetails?.isBdoIntegrated
      ? ORDER_STATUS.TRANSPORT_CONFIRMED : ORDER_STATUS.COURSE_COMPLETED;
  }

  function recover(state) {
    state.orderDetails.isDeleted = false;
  }

  function undoConfirm(state) {
    state.orderDetails.status = ORDER_STATUS.CREATED;
    state.orderDetails.driverActionsDetails.isConfirmedByDriver = false;
  }

  function unlinkKpoCard(state, action) {
    const { payload: { kpoId } } = action;
    let kpoCard = state.orderDetails.orderBdoInfo?.kpoCards.find(x => x.kpoId === kpoId);
    kpoCard.isLinked = false;
  }

  function linkKpoCard(state, action) {
    const { payload: { kpoId } } = action;
    let kpoCard = state.orderDetails.orderBdoInfo?.kpoCards.find(x => x.kpoId === kpoId);
    kpoCard.isLinked = true;
  }
}

function createExtraActions() {
  const baseUrl = `${process.env.REACT_APP_API_URL}/orders/views`;

  return {
    getListView: getListView(),
    getCalendarView: getCalendarView(),
    getVehicleView: getVehicleView(),
    getDriverView: getDriverView(),
    getDeletedListView: getDeletedListView(),
    getShippingCalendarView: getShippingCalendarView(),
    getSeriesListView: getSeriesListView(),
    getById: getById(),
    getSeriesById: getSeriesById(),
    getTransportDetails: getTransportDetails(),
    getMainDetails: getMainDetails(),
    getBdoInfoById: getBdoInfo(),
    refreshHistory: refreshHistory(),
    refreshStatus: refreshStatus(),
    search: search(),
    getWastesDetails: getWastesDetails(),
    getWastesInfoForTransfer: getWastesInfoForTransfer(),
    getOrderBasicInfo: getOrderBasicInfo(),
    getFileDocuments: getFileDocuments()
  };

  function getListView() {
    return createAsyncThunk(
      `${name}/getListView`,
      async({ pageNumber, pageSize, params }) => {
        return await fetchWrapper.get(`${baseUrl}${params ?? ""}`);
          // `${baseUrl}?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${pageSize ?? defaultPageSize}`);
      }
    );
  }

  function getCalendarView() {
    return createAsyncThunk(
      `${name}/getCalendarView`,
      async({ params }) => await fetchWrapper.get(`${baseUrl}/calendar${params}`)
    );
  }

  function getShippingCalendarView() {
    return createAsyncThunk(`${name}/getShippingCalendarView`, async({ date, includedVehicleType }) => {
      return await fetchWrapper.get(`${baseUrl}/shipping-calendar?date=${date}&includedVehicleType=${includedVehicleType ?? ""}`);
    });
  }

  function getVehicleView() {
    return createAsyncThunk(
      `${name}/getVehicleView`,
      async({ params }) => await fetchWrapper.get(`${baseUrl}/vehicles${params}`)
    );
  }

  function getDriverView() {
    return createAsyncThunk(
      `${name}/getDriverView`,
      async({ params }) => await fetchWrapper.get(`${baseUrl}/drivers${params}`)
    );
  }

  function getDeletedListView() {
    return createAsyncThunk(
      `${name}/getDeletedListView`,
      async({ pageNumber, pageSize }) => {
        return await fetchWrapper.get(`${baseUrl}/deleted?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${pageSize ?? defaultPageSize}`);
      }
    );
  }

  function getSeriesListView() {
    return createAsyncThunk(
      `${name}/getSeriesListView`,
      async({ pageNumber, pageSize, params }) => {
        return await fetchWrapper.get(`${baseUrl}/series?pageNumber=${pageNumber ?? defaultPageNumber}&pageSize=${pageSize ?? defaultPageSize}`);
      }
    );
  }

  function getById() {
    return createAsyncThunk(`${name}/getById`, async({ id, token }) => await fetchWrapper.get(`${baseUrl}/${id}`, null, token));
  }

  function getSeriesById() {
    return createAsyncThunk(`${name}/getSeriesById`, async({ id, token }) => await fetchWrapper.get(`${baseUrl}/series/${id}`, null, token));
  }

  function getTransportDetails() {
    return createAsyncThunk(`${name}/getTransactionDetailsById`, async({ id }) => await fetchWrapper.get(`${baseUrl}/${id}/transport-details`));
  }

  function getBdoInfo() {
    return createAsyncThunk(`${name}/getBdoInfoById`, async({ id }) => await fetchWrapper.get(`${baseUrl}/${id}/bdo-info`));
  }

  function refreshHistory() {
    return createAsyncThunk(`${name}/refreshHistory`, async({ id }) => await fetchWrapper.get(`${baseUrl}/${id}/logs`));
  }

  function refreshStatus() {
    return createAsyncThunk(`${name}/refreshStatus`, async({ id }) => await fetchWrapper.get(`${baseUrl}/${id}/state`));
  }

  function search() {
    return createAsyncThunk(`${name}/search`, async(payload) => {
      return await fetchWrapper.post(`${baseUrl}/search`, payload);
    });
  }

  function getWastesDetails() {
    return createAsyncThunk(`${name}/getWastesDetails`, async({ id }) => {
      return await fetchWrapper.get(`${baseUrl}/${id}/wastes-details`);
    });
  }

  function getMainDetails() {
    return createAsyncThunk(`${name}/getMainDetails`, async({ id }) => {
      return await fetchWrapper.get(`${baseUrl}/${id}/main-details`);
    });
  }

  function getWastesInfoForTransfer() {
    return createAsyncThunk(`${name}/getWastesInfoForTransfer`, async({ id }) => {
      return await fetchWrapper.get(`${baseUrl}/${id}/wastes-info-transfer`);
    });
  }

  function getOrderBasicInfo() {
    return createAsyncThunk(`${name}/getOrderBasicInfo`, async({ id }) => {
      return await fetchWrapper.get(`${baseUrl}/${id}/basic-info`);
    });
  }

  function getFileDocuments() {
    return createAsyncThunk(
      `${name}/getDocuments`,
      async ({ orderId }) =>
        await fetchWrapper.get(`${baseUrl}/${orderId}/files`));
  }
}

function createExtraReducers() {
  return {
    ...getListView(),
    ...getCalendarView(),
    ...getShippingCalendarView(),
    ...getDeletedListView(),
    ...getSeriesListView(),
    ...getById(),
    ...getSeriesById(),
    ...getTransactionDetailsById(),
    ...getBdoInfoById(),
    ...refreshHistory(),
    ...getWastesDetails(),
    ...getMainDetails(),
    ...refreshStatus(),
    ...getVehicleView(),
    ...getDriverView(),
    ...getFileDocuments()
  };

  function getListView() {
    var { pending, fulfilled, rejected } = extraActions.getListView;
    return {
      [pending]: (state) => {
        state.ordersListView = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.ordersListView = action.payload;
      },
      [rejected]: (state, action) => {
        state.ordersListView = { error: action.error };
      }
    };
  }

  function getCalendarView() {
    var { pending, fulfilled, rejected } = extraActions.getCalendarView;
    return {
      [pending]: (state, action) => {
        const { dates } = action.meta.arg;
        state.ordersCalendarView = { loading: true };
        state.calendarViewDates = dates;
      },
      [fulfilled]: (state, action) => {
        state.ordersCalendarView = action.payload;
      },
      [rejected]: (state, action) => {
        state.ordersCalendarView = { error: action.error };
      }
    };
  }

  function getShippingCalendarView() {
    var { pending, fulfilled, rejected } = extraActions.getShippingCalendarView;
    return {
      [pending]: (state) => {
        state.shippingCalendarView = { loading: true };
      },
      [fulfilled]: (state, action) => {
        const { payload } = action;
        const { items, availableSquarePlaceTypes } = payload;
        state.shippingCalendarView = { items: items, availableSquarePlaceTypes: availableSquarePlaceTypes };
      },
      [rejected]: (state, action) => {
        state.shippingCalendarView = { error: action.error };
      }
    };
  }

  function getVehicleView() {
    var { pending, fulfilled, rejected } = extraActions.getVehicleView;
    return {
      [pending]: (state, action) => {
        const { dates } = action.meta.arg;
        state.vehicleView = { loading: true };
        state.vehicleViewDates = dates;
      },
      [fulfilled]: (state, action) => {
        state.vehicleView = action.payload;
      },
      [rejected]: (state, action) => {
        state.vehicleView = { error: action.error };
      }
    };
  }

  function getDriverView() {
    var { pending, fulfilled, rejected } = extraActions.getDriverView;
    return {
      [pending]: (state, action) => {
        const { dates } = action.meta.arg;
        state.driverView = { loading: true };
        state.driverViewDates = dates;
      },
      [fulfilled]: (state, action) => {
        state.driverView = action.payload;
      },
      [rejected]: (state, action) => {
        state.driverView = { error: action.error };
      }
    };
  }

  function getSeriesListView() {
    var { pending, fulfilled, rejected } = extraActions.getSeriesListView;
    return {
      [pending]: (state) => {
        state.orderSeriesListView = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderSeriesListView = action.payload;
      },
      [rejected]: (state, action) => {
        state.orderSeriesListView = { error: action.error };
      }
    };
  }

  function getDeletedListView() {
    var { pending, fulfilled, rejected } = extraActions.getDeletedListView;
    return {
      [pending]: (state) => {
        state.ordersDeletedListView = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.ordersDeletedListView = action.payload;
      },
      [rejected]: (state, action) => {
        state.ordersDeletedListView = { error: action.error };
      }
    };
  }

  function getById() {
    var { pending, fulfilled, rejected } = extraActions.getById;
    return {
      [pending]: (state) => {
        state.orderDetails = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderDetails = action.payload;
      },
      [rejected]: (state, action) => {
        state.orderDetails = { error: action.error };
      }
    };
  }

  function getSeriesById() {
    var { pending, fulfilled, rejected } = extraActions.getSeriesById;
    return {
      [pending]: (state) => {
        state.orderSeriesDetails = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderSeriesDetails = action.payload;
      },
      [rejected]: (state, action) => {
        state.orderSeriesDetails = { error: action.error };
      }
    };
  }

  function getTransactionDetailsById() {
    var { pending, fulfilled } = extraActions.getTransportDetails;
    return {
      [pending]: (state, action) => {
        state.orderDetails.transportDetails = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderDetails.transportDetails = action.payload;
      }
    };
  }

  function getBdoInfoById() {
    var { pending, fulfilled } = extraActions.getBdoInfoById;
    return {
      [pending]: (state) => {
        // state.orderDetails.orderBdoInfo = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderDetails.orderBdoInfo = action.payload;
      }
    };
  }

  function getWastesDetails() {
    var { pending, fulfilled } = extraActions.getWastesDetails;
    return {
      [pending]: (state) => {
        state.orderDetails.wastesDetails = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderDetails.wastesDetails = action.payload;
      }
    };
  }

  function getMainDetails() {
    var { fulfilled } = extraActions.getMainDetails;
    return {
      [fulfilled]: (state, action) => {
        state.orderDetails.contractorDetails = { ...state.orderDetails.contractorDetails, ...action.payload };
      }
    };
  }

  function refreshHistory() {
    var { fulfilled } = extraActions.refreshHistory;
    return {
      [fulfilled]: (state, action) => {
        state.orderDetails.history = action.payload;
      }
    };
  }

  function refreshStatus() {
    var { pending, fulfilled } = extraActions.refreshStatus;
    return {
      [pending]: (state) => {
        state.orderDetails.status = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderDetails.status = action.payload;
      }
    };
  }

  function getFileDocuments() {
    var { pending, fulfilled } = extraActions.getFileDocuments;
    return {
      [pending]: (state) => {
        state.orderDetails.documents = { loading: true };
      },
      [fulfilled]: (state, action) => {
        state.orderDetails.documents = action.payload;
      }
    };
  }
}
