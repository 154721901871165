import React, { useEffect, useState } from "react";
import { Dropdown, Badge } from "react-bootstrap";
import s from "./style.module.css";
import { useDispatch, useSelector } from "react-redux";
import { DomainButton } from "../Abstractions/DomainButton";
import { ArrowRepeat } from "react-bootstrap-icons";
import { notificationsActions } from "../../_store/notifications.slice";
import { NotificationItem } from "./NotificationItem";
import { LoadingSpinner } from "../Abstractions/LoadingSpinner";
import { DomainPagination } from "../Abstractions/DomainPagination";
import { MultiSelectFilter } from "../Abstractions/MultiSelect/MultiSelectFilter";
import { FilterProvider } from "../Forms/FilterContext";
import { FilterSwitch } from "../Abstractions/FilterSwitch";
import { TransactionType } from "../../domain/TransactionType";

export function NotificationIcon({ header, originatorType, Icon, interval = 20 }) {
  const dispatch = useDispatch();
  const { notifications } = useSelector(x => x.notifications);
  const notificationKey = originatorType.toLowerCase();
  const { items, paging } = notifications[notificationKey];
  const [formFilters, setFormFilters] = useState({ hideRead: true, sortByLoadingDate: false });

  const handleOnClick = async(notification) => {
    if(notification.isRead) {
      return;
    }

    await dispatch(notificationsActions.update({ id: notification.id, key: notificationKey }));
  }

  const unreadCount = paging?.totalUnread;
  const refreshNotifications = async(pageNumber, pageSize, filters, skipCache = false) => {
    if(!pageNumber || !pageSize){
      pageNumber = paging?.pageNumber ?? 1;
      pageSize = paging?.pageSize ?? 50;
    }
    filters ??= formFilters;

    await dispatch(notificationsActions.getAll({ pageNumber, pageSize, originatorType, skipCache, ...filters,  }));
  }

  const onFilterChanged = async(param) => {
    const { name, value } = param;
    const newFilters = { ...formFilters, [name]: value };
    setFormFilters(newFilters);
    await refreshNotifications(null, null, newFilters);
  }

  useEffect(() => {
    refreshNotifications();

    const intervalInMinutes = interval * 60 * 1000;
    const intervalId = setInterval(() => refreshNotifications(), intervalInMinutes);

    return () => clearInterval(intervalId);

  }, []);

  return (
    <Dropdown data-bs-theme="light" align="end">
      <Dropdown.Toggle variant="link" id="dropdown-notifications" className="text-decoration-none">
        <Icon color="#7bb49b" size={20}/>
        {unreadCount > 0 && <Badge pill bg="danger" className={s.notificationIcon}>
          {unreadCount}
        </Badge>}
      </Dropdown.Toggle>

      <Dropdown.Menu className={s.notificationsMenu}>
        <Dropdown.Header className="pb-0">
          <h6 className="mb-2">{header}</h6>
          <div className="d-flex gap-2 align-items-end pb-0">
            <DomainButton className="align-self-baseline" onClick={() => refreshNotifications(null, null, null, true)} size="smaller" variant="primary"><ArrowRepeat/></DomainButton>
            <FilterProvider onFilterChange={onFilterChanged} defaultValues={formFilters}>
              <MultiSelectFilter className="x-small" name="transactionTypes" options={TransactionType} title="Typ transakcji"/>
              <FilterSwitch name="hideRead" label="Ukryj przeczytane"/>
              <FilterSwitch name="sortByLoadingDate" label="Sortuj po dacie transportu"/>
            </FilterProvider>
          </div>
        </Dropdown.Header>
        <hr/>
        <LoadingSpinner isLoading={items?.loading} as="div" center/>
        {items?.length > 0 ? (
          items.map(notification => (
            <NotificationItem key={notification.id} handleOnClicked={handleOnClick} notification={notification}/>
          ))
        ) : (
          <Dropdown.Item className="small" disabled>Brak nowych powiadomień</Dropdown.Item>
        )}
        <hr/>
        <div className="p-2">
          <DomainPagination paging={paging} itemsCount={items?.length} onPageChange={refreshNotifications} minimalPagination/>
        </div>
      </Dropdown.Menu>
    </Dropdown>
  );
}