import { StatusBadge } from "_components/Abstractions/StatusBadge";
import { userActions } from "_store";
import { USER_DISPLAY, UserRoles } from "domain/UserRoles";
import { Badge, Table } from "react-bootstrap";
import { ChangeAccountState } from "useCases/accounts/ChangeAccountState";
import { UpdateAccount } from "useCases/accounts/UpdateAccount";
import { SortedTh } from "../Abstractions/SortedTh";
import { ResetPassword } from "../../useCases/accounts/ResetPassword";

export function UserTable({ users, sorting, itemActions }) {
  const columnsNames = {
    "login": "Login",
    "name": "Imię",
    "lastName": "Nazwisko",
  }

  return (
    <Table hover>
      <thead>
        <tr>
          {Object.entries(columnsNames).map(([value, name]) =>
            <SortedTh key={value} sorting={sorting} itemActions={itemActions} sortingValue={value}>{name}</SortedTh>)}
          <th>Telefon</th>
          <th>Rola</th>
          <th>Status</th>
          <th>BDO Link</th>
          <th>Akcje</th>
        </tr>
      </thead>
      <tbody>
        {users.loading && (
          <>
            <tr className="spinner-border"></tr>
          </>
        )}
        {users.length > 0 &&
          users.map((user) => (
            <tr key={user.id}>
              <td style={{ textTransform: "none" }}>{user.login}</td>
              <td style={{ width: "15%" }}>{user.name}</td>
              <td style={{ width: "15%" }}>{user.lastName}</td>
              <td style={{ width: "8%" }}>{user.phone}</td>
              <td style={{ width: "8%" }}>
                <Badge bg={USER_DISPLAY[user.userRole]?.color}>
                  {UserRoles[user.userRole]}
                </Badge>
              </td>
              <td style={{ width: "8%" }}>
                <StatusBadge isActive={user.isActive}/>
              </td>
              <td style={{ width: "5%" }}>
                <StatusBadge isActive={user.isBdoLinked}/>
              </td>
              <td>
                <UpdateAccount user={user}/>
                <ResetPassword userId={user.id} login={user.login}/>
                <ChangeAccountState user={user} actions={userActions}/>
              </td>
            </tr>
          ))}
      </tbody>
    </Table>
  );
}
